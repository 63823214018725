import React from "react";
import { Route } from "react-router";
import ProtectedRoute from "./components/protectedRoute";
import Layout from "./components/Layout";
import Login from "./components/login";
import Logout from "./components/logout";
import Settings from "./components/settings";
import UserManagement from "./components/user-management";
import User from "./components/user-management/user";
import Roles from "./components/user-management/roles";
import Role from "./components/user-management/roles/role";
import Permissions from "./components/user-management/permissions";
import Permission from "./components/user-management/permissions/permission";
import FormManagement from "./components/form-management";
import Form from "./components/form-management/form";
import PackageManagement from "./components/package-management";
import Package from "./components/package-management/package";
import EmailTemplateManagement from "./components/email-template-management";
import EmailTemplate from "./components/email-template-management/email-template";
import PatientDashbaord from "./components/patient-dashboard";
import ApplicationManagement from "./components/application-management";
import ApplicationForm from "./components/application-management/application-form";
import LocationManagement from "./components/location-management";
import Location from "./components/location-management/location";
import ExportForm from "./components/export-form";
import ExportAll from "./components/export-all";
import StaffScreening from "./components/staff-screening";
import ResetPassword from "./components/reset-password";
import COVIDRapidTest from "./components/covid-rapid-test";

export default () => (
  <Layout>
    <Route exact path="/" component={Login} />
    <Route exact path="/login" component={Login} />
    <ProtectedRoute
      path="/reset-password"
      component={ResetPassword}
    />
    <ProtectedRoute
      path="/application-management"
      component={ApplicationManagement}
    />
    <ProtectedRoute
      path="/application-form/:userId/:packageId/:id"
      component={ApplicationForm}
    />
    <ProtectedRoute
      path="/export-form/:userId/:packageId/:id/:readOnly"
      component={ExportForm}
    />
    <ProtectedRoute
      path="/export-all/:userId/:packageId"
      component={ExportAll}
    />
    <ProtectedRoute path="/patient-dashboard" component={PatientDashbaord} />
    <ProtectedRoute path="/user-management" component={UserManagement} />
    <ProtectedRoute path="/user/:id" component={User} />
    <ProtectedRoute path="/form-management" component={FormManagement} />
    <ProtectedRoute path="/form/:id" component={Form} />
    <ProtectedRoute
      path="/email-template-management"
      component={EmailTemplateManagement}
    />
    <ProtectedRoute path="/email-template/:id" component={EmailTemplate} />
    <ProtectedRoute path="/roles" component={Roles} />
    <ProtectedRoute path="/role/:id" component={Role} />
    <ProtectedRoute path="/permissions" component={Permissions} />
    <ProtectedRoute path="/permission/:id" component={Permission} />
    <ProtectedRoute path="/package-management" component={PackageManagement} />
    <ProtectedRoute path="/package/:id" component={Package} />
    <ProtectedRoute
      path="/location-management"
      component={LocationManagement}
    />
    <ProtectedRoute path="/location/:id" component={Location} />
    <ProtectedRoute path="/settings" component={Settings} />
    <Route path="/logout" component={Logout} />
    <Route exact path="/staff-screening" component={StaffScreening} />
    <Route exact path="/covid-rapid-test" component={COVIDRapidTest} />
  </Layout>
);
