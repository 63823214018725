import React from 'react'
import { 
    Tooltip,IconButton
    } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';

const EditButton = ({...props}) => {

    const { handleEdit, pid } = props;

    return (
        <Tooltip title="Edit" onClick={e => handleEdit(pid)}>
            <IconButton aria-label="edit">
                <EditIcon />
            </IconButton>
        </Tooltip>   
       )
}

export default EditButton;