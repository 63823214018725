import React, { Fragment, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUserError, login } from "../../store/user";
import { Button, Grid, TextField, makeStyles } from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import { loginSchema } from "./login-schema";
import logo from "../../Images/medsleep-logo.jpg";
import {
  isPatient,
} from "../../utils/constants";
import { history } from "../../utils/history";

const useStyles = makeStyles((theme) => ({
  centeredForm: theme.centeredForm,
  logo: theme.logo,
  errorMessage: {
    marginTop: "1rem",
  },
  button: theme.button,
}));

const Login = ({ ...props }) => {
  const classes = useStyles();

  useEffect(() => {
    const init = () => {};
    init();
  }, []);

  const onSubmit = async (values) => {
    const { login } = props;
    const { username, password } = values;
    const { payload: user } = await login(username, password);

    if (user.user) {

      if (isPatient(user.user.roleId)) {
        
        if(user.user.resetPasswordRequired){
          history.push("/reset-password");
        }
        else{
          history.push("/patient-dashboard");
        }
      }   
      else {
        history.push("/application-management");
      }

    }
  };

  return (
    <Fragment>
      <div className="centered-container">
        <Grid
          container
          alignContent="center"
          justify="center"
          margin="normal"
          spacing={4}
        >
          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={(values) => {
              onSubmit(values);
            }}
            render={({
              values,
              setFieldValue,
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
              touched,
              ...formProps
            }) => (
              <form onSubmit={handleSubmit} className={classes.centeredForm}>
                <Grid item xs={12}>
                  <img src={logo} className={classes.logo} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errors.username && touched.username ? true : false}
                    name="username"
                    type="text"
                    label="Username"
                    margin="normal"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                  />
                  <ErrorMessage name="username">
                    {(msg) => <div className="error error-message">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errors.password && touched.password ? true : false}
                    name="password"
                    type="password"
                    label="Password"
                    margin="normal"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <ErrorMessage name="password">
                    {(msg) => <div className="error error-message">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} align="right">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Login
                  </Button>
                </Grid>
              </form>
            )}
          />
          <Grid
            container
            alignContent="center"
            justify="center"
            className={classes.errorMessage}
          >
            {props.error && (
              <div className="error error-message">{props.error}</div>
            )}
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    error: getUserError(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ login }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
