import React from 'react'
import { 
    Button
    } from '@material-ui/core';

import PrintIcon from '@material-ui/icons/Print';


const PrintButton = ({...props}) => {

    const { componentRef, handlePrint } = props;

    return (
        <Button 
            variant="contained" 
            type="button" 
            color="primary"
            startIcon={<PrintIcon />}
            onClick={e => handlePrint(componentRef)}>
                Print </Button>
    )
}

export default PrintButton;