import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "../../../utils/history";
import {
  Grid,
  makeStyles,
  Button,
  TextField,
  Typography,
  Chip,
} from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { emailTemplateSchema } from "./email-template-schema";
import { toast } from "react-toastify";
import DescriptionIcon from "@material-ui/icons/Description";
import {
  getEmailTemplates,
  getEmailTemplate,
  getEmailTemplateSelector,
  getEmailTemplatesSelector,
  saveEmailTemplate,
  saveEmailTemplateAttachments,
  getEmailTemplateAttachments,
  getEmailTemplateAttachmentsSelector,
  deleteEmailTemplateAttachment,
} from "../../../store/admin";

const useStyles = makeStyles((theme) => ({
  backArrowOutter: theme.backArrowOutter,
  darkArrow: theme.darkArrow,
  form: theme.form,
  select: theme.select,
  uploadButton: {
    marginBottom: "1rem",
  },
}));

const EmailTemplate = ({ ...props }) => {
  const classes = useStyles();
  const {
    match: { params },
    emailTemplate = {},
  } = props;
  const [disabledSubmit, setDisableSubmit] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileUpload, setShowFileUpload] = useState(false);

  useEffect(() => {
    const init = async () => {
      const {
        getEmailTemplate,
        getEmailTemplates,
        getEmailTemplateAttachments,
      } = props;
      const {
        match: { params },
      } = props;

      await getEmailTemplates();

      if (params.id !== "new") {
        await getEmailTemplate(params.id);
        setShowFileUpload(true);
        getEmailTemplateAttachments(params.id);
      }
    };
    init();
  }, []);

  const handleSave = async (values) => {
    setDisableSubmit(true);

    const {
      match: { params },
      saveEmailTemplate,
      emailTemplates,
    } = props;

    const emailTemplate = {
      id: params.id === "new" ? 0 : params.id,
      name: values.name,
      templateId: values.templateId,
    };

    const exists = emailTemplates.find((et) => et.name === emailTemplate.name);

    if (exists && exists.id !== parseInt(params.id)) {
      toast.error(
        `Email template already exists with name ${emailTemplate.name}`
      );
      setDisableSubmit(false);
      return;
    } else {
      await saveEmailTemplate(emailTemplate);
      history.push("/email-template-management");
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    const {
      match: { params },
      saveEmailTemplateAttachments,
    } = props;

    const data = new FormData();

    for (var x = 0; x < selectedFile.length; x++) {
      data.append("file", selectedFile[x]);
    }

    data.append("emailTemplateId", params.id);

    await saveEmailTemplateAttachments(data);
  };

  const handleDelete = async (e, emailTemplateId) => {
    e.preventDefault();
    const { deleteEmailTemplateAttachment } = props;
    deleteEmailTemplateAttachment(emailTemplateId);
  };

  const onChangeHandler = (e) => {
    setSelectedFile(e.target.files);
  };

  const { emailTemplateAttachments } = props;

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.backArrowOutter}>
          <span
            onClick={(e) => history.push("/email-template-management")}
            className={classes.darkArrow}
          >
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Back to Email
            Template Management
          </span>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            {params.id === "new"
              ? "New Email Template"
              : `Edit Email Template ${params.id} `}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: emailTemplate.name ? emailTemplate.name : "",
              templateId: emailTemplate.templateId
                ? emailTemplate.templateId
                : "",
            }}
            validationSchema={emailTemplateSchema}
            onSubmit={(values) => {
              handleSave(values);
            }}
            render={({
              values,
              setFieldValue,
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
              touched,
              ...formProps
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      error={errors.name && touched.name ? true : false}
                      name="name"
                      type="text"
                      label="Name"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <ErrorMessage name="name">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      error={
                        errors.templateId && touched.templateId ? true : false
                      }
                      name="templateId"
                      type="text"
                      label="Template Id"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.templateId}
                    />
                    <ErrorMessage name="templateId">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12}>
                    {!showFileUpload && (
                      <Typography>
                        You must first create/save your email template to add
                        attachments.
                      </Typography>
                    )}
                    {showFileUpload && (
                      <Fragment>
                        <div className="form-group files color">
                          <label>Upload Your File(s)</label>
                          <input
                            type="file"
                            className="form-control"
                            multiple
                            onChange={(e) => onChangeHandler(e)}
                          />
                        </div>
                        <Button
                          className={classes.uploadButton}
                          variant="outlined"
                          color="primary"
                          onClick={(e) => handleUpload(e)}
                          disabled={selectedFile === null}
                        >
                          Upload
                        </Button>
                        <Grid container spacing={2}>
                          {emailTemplateAttachments &&
                            emailTemplateAttachments.map(
                              (emailTemplateAttachment) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={4}
                                  key={emailTemplateAttachment.id}
                                >
                                  <Chip
                                    label={emailTemplateAttachment.filename}
                                    icon={<DescriptionIcon />}
                                    onDelete={(e) =>
                                      handleDelete(
                                        e,
                                        emailTemplateAttachment.id
                                      )
                                    }
                                    color="primary"
                                  />
                                </Grid>
                              )
                            )}
                        </Grid>
                      </Fragment>
                    )}
                  </Grid>

                  <Grid item xs={12} align="right">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={disabledSubmit}
                    >
                      Save Email Template
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    emailTemplate: getEmailTemplateSelector(state),
    emailTemplates: getEmailTemplatesSelector(state),
    emailTemplateAttachments: getEmailTemplateAttachmentsSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        getEmailTemplate,
        getEmailTemplates,
        saveEmailTemplate,
        getEmailTemplateAttachments,
        saveEmailTemplateAttachments,
        deleteEmailTemplateAttachment,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplate);
