import React, { Fragment } from "react";
import { getNameFromLocationId, MenuProps } from "../../../../utils/constants";
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Chip,
} from "@material-ui/core";

const SecondaryLocations = ({ ...props }) => {
  const {
    locations,
    values,
    handleSelectChange,
    setFieldValue,
    classes,
  } = props;

  var secondaryLocations = locations.filter(l => l.id != values.locationId);

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Secondary Location(s)
          </Typography>
        </Grid>
        <Fragment>
          <Grid item xs={12}>
            <FormControl
              className={
                locations.length > 0
                  ? classes.multiSelect
                  : classes.multiSelectEmpty
              }
            >
              <InputLabel id="secondaryLocationIds">Secondary Location(s)</InputLabel>
              <Select
                id="secondaryLocationIds"
                multiple
                value={values.secondaryLocationIds}
                onChange={(e) =>
                  handleSelectChange(e, setFieldValue, "secondaryLocationIds")
                }
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value, index) => (
                      <Chip
                        key={`${value}-${index}`}
                        label={getNameFromLocationId(value, locations)}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {secondaryLocations &&
                  secondaryLocations.map((secondaryLocation) => (
                    <MenuItem key={secondaryLocation.id} value={secondaryLocation.id}>
                      {secondaryLocation.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Fragment>
      </Grid>
    </Fragment>
  );
};

export default SecondaryLocations;
