import React, { Fragment } from 'react'
import { 
    Typography
} from '@material-ui/core';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import UpdateIcon from '@material-ui/icons/Update';




const PackageStatus = ({...props}) => {

    const { isComplete } = props;

    return (
        <Fragment>
            {isComplete && 
                <Typography variant="overline" style={{color:"green"}}>
                    <DoneAllIcon /> Complete 
                </Typography>
            }
            {!isComplete &&
                <Typography variant="overline">
                    <UpdateIcon /> Pending
                </Typography>
            }
        </Fragment>         
    )
}

export default PackageStatus;