import React from 'react'
import { 
    Tooltip,IconButton
    } from '@material-ui/core';

import DehazeIcon from '@material-ui/icons/Dehaze';

const ExportAllButton = ({...props}) => {

    const { handleExportAll, packageId, userId} = props;

    return (
        <Tooltip title="Export All" onClick={e => handleExportAll(e, packageId, userId)}>
            <IconButton aria-label="exportAll">
                <DehazeIcon />
            </IconButton>
        </Tooltip>     
    )
}

export default ExportAllButton;