import React from "react";
import { Grid } from "@material-ui/core";
import { Form } from "react-formio";
import emailService from "../../services/emailService";
import { staffScreening } from "../../utils/constants";

const StaffScreening = ({ ...props }) => {
  const handleSubmitDone = async (values) => {
    var cleared = window.document.getElementsByClassName("cleared")[0];

    if (cleared) {
      values.data.result = true;
    }

    var emailData = {
      tos: [values.data.email, values.data.manager, staffScreening.hrEmail],
      data: JSON.stringify(values.data),
      templateId: cleared
        ? staffScreening.clearedEmailTemplateId
        : staffScreening.failedEmailTemplateId,
    };

    emailService.send(emailData);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Form
          name="form"
          src={staffScreening.formUrl}
          onSubmitDone={(result) => handleSubmitDone(result)}
        />
      </Grid>
    </Grid>
  );
};

export default StaffScreening;
