import http from "./httpService";
import jwtDecode from "jwt-decode";

const apiEndPoint = `${window.location.origin}/api/auth/Authenticate`;
const tokenKey = "token";


export async function login(username, password) {
  const { data: jwt } = await http.post(apiEndPoint, { username, password });
  http.setJwt(jwt);
  localStorage.setItem(tokenKey, jwt);
}

export async function formioLogin(authEndpoint, username, password) {

  var body = {
    data: {
      email: username,
      password
    }
  }
  const { headers } = await http.post(`${authEndpoint}/user/login`, body);
  http.setFormioJwt(headers["x-jwt-token"])

}

export async function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export async function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function getFormioJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  formioLogin,
  logout,
  getCurrentUser,
  loginWithJwt,
  getJwt,
  getFormioJwt
};
