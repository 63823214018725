import React from 'react';
import {Formik, ErrorMessage} from 'formik';
import { Button, Grid, Typography, TextField, Link } from '@material-ui/core';
import { formIOSettingsSchema } from './form-io-settings-schema';

const FormIOSettings = ({ ...props }) => {

    const { configuration, handleSaveFormIOSettings} = props;
    const { formioLiveEndpoint, formioUsername, formioPassword } = configuration;

    return(
        <Formik
            enableReinitialize={true}
            initialValues={
            { 
                liveEndpoint: formioLiveEndpoint ? formioLiveEndpoint : "",
                username: formioUsername ? formioUsername : "",
                password: formioPassword ? formioPassword: ""
            }}
            validationSchema={formIOSettingsSchema}

            onSubmit={(values) => {
                handleSaveFormIOSettings(values);
            }}

            render={({ values, setFieldValue, handleChange, handleSubmit, handleBlur, errors, touched, ...formProps }) => (
                <form onSubmit={handleSubmit}>
                    
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            Form.io Settings
                        </Typography>             
                    </Grid>
                    <Grid item xs={12}>    
                        <Link
                            component="button"
                            
                            variant="body2"
                            onClick={(e) => {
                                e.preventDefault();
                                window.open(formioLiveEndpoint, '_blank');
                            }}
                            >
                            Form.io Dashboard
                        </Link>            
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={(errors.liveEndpoint && touched.liveEndpoint) ? true : false}
                            name="liveEndpoint"
                            type="text"
                            label="Live Endpoint"
                            type="password"
                            margin="normal"
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.liveEndpoint}
                        />
                        <ErrorMessage name="liveEndpoint">{msg => <div className="error error-message">{msg}</div>}</ErrorMessage>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={(errors.username && touched.username) ? true : false}
                            name="username"
                            type="text"
                            label="Username"
                            type="password"
                            margin="normal"
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                        />
                        <ErrorMessage name="username">{msg => <div className="error error-message">{msg}</div>}</ErrorMessage>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={(errors.password && touched.password) ? true : false}
                            name="password"
                            type="text"
                            label="Password"
                            type="password"
                            margin="normal"
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                        />
                        <ErrorMessage name="password">{msg => <div className="error error-message">{msg}</div>}</ErrorMessage>
                    </Grid>
                    <Grid item xs={3} align="right">
                        <Button type="submit" variant="contained" color="primary" fullWidth>Save</Button>
                    </Grid>
                
                </form>
            )}
        />
    );    
}

export default FormIOSettings