import React, { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form } from "react-formio";
import {
  makeStyles,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import {
  getFormSelector,
  getForm,
  getSubmission,
  getLocation,
  getLocationSelector,
  getUser,
} from "../../store/admin";
import { getConfiguration, getSettings } from "../../store/setting";
import { Helmet } from "react-helmet";
import logo from "../../Images/medsleep-logo.jpg";
import { downloadAttachment } from "../../utils/file-helper";

const useStyles = makeStyles((theme) => ({
  button: theme.button,
  paper: theme.paper,
  exportLogo: theme.exportLogo,
  header: {
    marginBottom: "1rem",
  },
  linearProgress: theme.linearProgress,
}));

const ExportForm = ({ ...props }) => {
  const classes = useStyles();
  const {
    match: { params },
    getForm,
    getSettings,
    getSubmission,
    getLocation,
    getUser,
  } = props;
  const [formURL, setFormURL] = useState("");
  const [formSubmission, setFormSubmission] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      const { userId, packageId, id } = params;

      var patientSubmission = {
        userId,
        packageId,
        formId: id,
      };

      const {
        payload: { submission },
      } = await getSubmission(patientSubmission);

      if (submission.submission) {
        var data = {
          data: JSON.parse(submission.submission),
        };
        setFormSubmission(data);
        downloadAttachment(data.data);
      }

      const {
        payload: { user },
      } = await getUser(userId);
      await getLocation(user.locationId);

      const {
        payload: { configuration },
      } = await getSettings();
      const {
        payload: { form },
      } = await getForm(id);
      const { formioLiveEndpoint } = configuration;
      setFormURL(`${formioLiveEndpoint}/${form.apiPath}`);
    };
    init();
  }, []);

  const { location } = props;
  return (
    <Fragment>
      <Helmet>
        <title>{props.form.name}</title>
      </Helmet>
      {location && formSubmission && (
        <Fragment>
          {loading && (
            <Fragment>
              <Typography>Exporting...</Typography>
              <LinearProgress className={classes.linearProgress} />
            </Fragment>
          )}
          <Grid container spacing={4} className={classes.header}>
            <Grid item xs={12} sm={6}>
              <img
                src={logo}
                className={classes.exportLogo}
                alt="MedSleep Logo"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">{location.name}</Typography>
              <Typography>{location.address}</Typography>
              <Typography>{location.telephone}</Typography>
              <Typography>{location.fax}</Typography>
              <Typography>{location.email}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Form
              src={formURL}
              options={{ readOnly: params.readOnly === "true" }}
              submission={formSubmission}
              onRender={function () {        

                document
                  .getElementsByTagName("nav")[0]
                  .setAttribute("style", "display:none");
                window.document
                  .getElementsByClassName("btn")[0]
                  .setAttribute("style", "display:none");
                setLoading(true);
                setTimeout(function () {
                  setLoading(false);
                  window.print();
                }, 5000);
              }}
            />
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    form: getFormSelector(state),
    configuration: getConfiguration(state),
    location: getLocationSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      { getForm, getSettings, getSubmission, getLocation, getUser },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportForm);
