import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect'
import adminService from '../services/adminService';
import {toast} from 'react-toastify';

const slice = createSlice({

  name: 'setting',
  initialState: { 
    configuration:'',
  },
  reducers: {

    setSettings: (state, action) => {
            
      const { configuration } = action.payload;
      state.configuration = configuration;
    }

  }

});

//action creators
export const getSettings = () =>  async (dispatch) => {  
  const {data: configuration} = await adminService.getSettings();
  return dispatch({type: setSettings.type, payload: {configuration}});
};

export const saveSettings = (setting) =>  async (dispatch) => {  
  
  try{
    const {data: configuration} = await adminService.saveSettings(setting);
    dispatch({type: setSettings.type, payload: {configuration}});
    successMessage();

  }
  catch(e){
    failureMessage(e.message);
  }

};

function successMessage(){
  toast.success(`Settings updated successfully!`);
}

function failureMessage(message){
  toast.error(`Settings failed to update ${message}!`);
}

export const { setSettings } = slice.actions;
export const reducer = slice.reducer;

//memoized selector
export const getConfiguration = createSelector(

    state => state.setting.configuration,
    (configuration) => {
        return configuration;
    }
);

