import React, { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getConfiguration, getSettings } from "../../store/setting";
import { getSubmission } from "../../store/admin";
import auth from "../../services/authService";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import {
  makeStyles,
  Button,
  Grid,
  Typography,
  Paper,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import {
  getUserPackagesSelector,
  saveSubmission,
  lockPackage,
} from "../../store/user";
import PackageStatus from "../shared/package-status";
import FormStepper from "./form-stepper";
import FormView from "./form-view";

const useStyles = makeStyles((theme) => ({
  button: theme.button,
  paper: theme.paper,
}));

const PatientDashboard = ({ ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedPackageId, setSelectedPackageId] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState(0);
  const [maxSteps, setMaxSteps] = useState(0);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({});
  const [formURL, setFormURL] = useState("");
  const [formSubmission, setFormSubmission] = useState({});

  useEffect(() => {
    const init = async () => {
      const { getSettings } = props;
      await getSettings();
    };
    init();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmSubmission = async () => {
    const token = await auth.getCurrentUser();
    lockCollapsePackage(token.primarysid);
  };

  const lockCollapsePackage = (userId) => {
    const { lockPackage } = props;

    const patientSubmission = {
      packageId: selectedPackageId,
      userId,
    };
    lockPackage(patientSubmission);
    setOpen(false);
    setSelectedPackageId(0);
  };

  const onNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const currentStep = activeStep + 1;
    setFormDetails(currentStep, selectedPackageId);
  };

  const onBack = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const currentStep = activeStep - 1;
    setFormDetails(currentStep, selectedPackageId);
  };

  const setFormDetails = async (step, packageId) => {
    const { userPackages, configuration, getSubmission } = props;
    const userPackage = userPackages.find((up) => up.package.id === packageId);
    const { form } = userPackage.forms[step];

    setForm(form);
    setFormURL(`${configuration.formioLiveEndpoint}/${form.apiPath}`);

    const token = await auth.getCurrentUser();

    //get latest submission
    var patientSubmission = {
      userId: token.primarysid,
      packageId,
      formId: form.id,
    };

    const {
      payload: { submission },
    } = await getSubmission(patientSubmission);

    if (submission.submission) {
      var data = {
        data: JSON.parse(submission.submission),
      };
      setFormSubmission(data);
    }

    scroller.scrollTo("form", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const onStartPackage = (e, packageId) => {
    e.preventDefault();
    setSelectedPackageId(packageId);
    setActiveStep(0);

    const { userPackages } = props;
    const userPackage = userPackages.find((up) => up.package.id === packageId);
    const formNames = userPackage.forms.map((f) => f.form.name);

    setFormDetails(0, packageId);
    setSteps(formNames);
    setMaxSteps(formNames.length);
  };

  const onSubmitDone = async (result) => {
    const { saveSubmission, lockPackage } = props;
    const { _id, data } = result;

    const token = await auth.getCurrentUser();

    var patientSubmission = {
      userId: token.primarysid,
      packageId: parseInt(selectedPackageId),
      formId: parseInt(form.id),
      submissionId: _id,
      submission: JSON.stringify(data),
    };

    const isPackageComplete = await saveSubmission(patientSubmission);

    if (isPackageComplete) {
      //if all the forms are complete prompt to lock
      const { userPackages } = props;
      const userPackage = userPackages.find(
        (up) => up.package.id === selectedPackageId
      );

      if (userPackage.package.isConsentPackage) {
        lockCollapsePackage(token.primarysid);
      } else {
        setOpen(true);
      }
    }

    if (activeStep === maxSteps - 1) return;

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const currentStep = activeStep + 1;
    setFormDetails(currentStep, selectedPackageId);
  };

  const onPrint = () => {
    const token = auth.getCurrentUser();
    window.open(
      `/export-form/${token.primarysid}/${selectedPackageId}/${form.id}/true`,
      "Export Form",
      "width=500,height=500"
    );
  };

  const onSingleSelect = async (index) => {
    setFormDetails(index, selectedPackageId);
    setActiveStep(index);
  };

  const PatientPackageView = ({ userPackage }) => {
    return (
      <Paper
        className={classes.paper}
        elevation={3}
        key={userPackage.package.id}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4">{userPackage.package.name}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            {!userPackage.isLocked && (
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => onStartPackage(e, userPackage.package.id)}
              >
                {userPackage.isComplete ? "View Submissions" : "Start"}
              </Button>
            )}
          </Grid>
          <Grid item xs={6} sm={3}>
            <PackageStatus isComplete={userPackage.isComplete} />
          </Grid>

          {selectedPackageId === userPackage.package.id && (
            <Fragment>
              {form && (
                <FormView
                  userPackage={userPackage}
                  handleSingleSelect={onSingleSelect}
                  handlePrint={onPrint}
                  formURL={formURL}
                  formSubmission={formSubmission}
                  handleSubmitDone={onSubmitDone}
                />
              )}
              {/* <Grid item xs={12}>
                                <FormStepper
                                    maxSteps={maxSteps}
                                    activeStep={activeStep}
                                    handleNext={onNext}
                                    handleBack={onBack}
                                    />
                            </Grid> */}
            </Fragment>
          )}
        </Grid>
      </Paper>
    );
  };

  const ConsentPackagesView = ({ userPackages }) => {
    return (
      <Fragment>
        {userPackages &&
          userPackages
            .filter((up) => up.package.isConsentPackage)
            .map((userPackage) => (
              <PatientPackageView
                key={userPackage.package.id}
                userPackage={userPackage}
              />
            ))}
      </Fragment>
    );
  };

  const UserPackagesView = ({ userPackages }) => {
    return (
      <Fragment>
        {userPackages
          .filter((up) => up.package.isConsentPackage)
          .every((up) => up.isComplete) &&
          userPackages &&
          userPackages
            .filter((up) => !up.package.isConsentPackage)
            .map((userPackage) => (
              <PatientPackageView
                key={userPackage.package.id}
                userPackage={userPackage}
              />
            ))}
      </Fragment>
    );
  };

  const { userPackages } = props;

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Patient Dashboard
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          For each package listed below please fill and submit required form(s).
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ConsentPackagesView userPackages={userPackages} />
      </Grid>
      <Grid item xs={12}>
        <UserPackagesView userPackages={userPackages} />
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Application Submission"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All the forms are completed. Do you want to submit your application
            now? Please be aware that by submitting your application you will no
            longer be able to edit forms.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleConfirmSubmission}
            color="primary"
          >
            Yes
          </Button>
          <Button
            variant="contained"
            onClick={handleClose}
            color="primary"
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userPackages: getUserPackagesSelector(state),
    configuration: getConfiguration(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      { getSettings, getSubmission, saveSubmission, lockPackage },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDashboard);
