import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { history } from '../../utils/history';
import { 
    makeStyles, 
    Grid, 
    Typography, 
    Table, 
    TableBody, 
    TableRow, 
    TableCell, 
    TablePagination, 
    TableContainer, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Link,
    Button } from '@material-ui/core';

import { getConfiguration, getSettings } from '../../store/setting';
import { getFormsSelector, getForms, clearForm, deleteForm } from '../../store/admin';
import { TablePaginationActions } from '../shared/pagination';
import { EnhancedTableHead, stableSort, getSorting } from '../shared/tableHeader';
import EditButton from '../shared/edit-button';
import DeleteButton from '../shared/delete-button/delete-button-view';

const useStyles = makeStyles(theme => ({
    button: theme.button
}));


const UserManagement = ({ ...props }) => {

    const classes = useStyles();      
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [open, setOpen] = useState(false);
    const [selectedFormId, setSelectedFormId] = useState(0);

    
    useEffect(() => {
        const init = async () => {
            const { getForms, clearForm, getSettings } = props;
            await getSettings();
            await getForms();
            clearForm();
        }
        init();
        
    }, []);

    const handleClose = () => {
        setOpen(false);
        setSelectedFormId(0);
    };

    const onDelete = (id) => {
        setSelectedFormId(id);
        setOpen(true);
    }

    const handleConfirmDelete = async () => {

        const {deleteForm,clearForm} = props;
        await deleteForm(selectedFormId)
        clearForm();
        setOpen(false);
    }

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = e => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };    

    const handleRequestSort = (e, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };
    
    const onEdit = (id) => {
        history.push(`/form/${id}`);
    }

    const headCells = [
        { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'apiPath', numeric: false, disablePadding: false, label: 'API Path' },
        { id: 'edit', numeric: false, disablePadding: false, label: '' },
        { id: 'delete', numeric: false, disablePadding: false, label: '' },
    ];
    
    return(
        <Fragment>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={9}>
                    <Typography variant="h3" gutterBottom>
                        Form Management
                    </Typography>
                </Grid>
 
                <Grid item xs={12} sm={3}>
                    <Button  variant="contained" type="button" color="secondary" className={classes.button} onClick={e => history.push(`/form/new`)}>New Form</Button>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={(e) => {
                            e.preventDefault();
                            window.open(props.configuration.formioLiveEndpoint, '_blank');
                        }}
                        >
                        Create and manage forms at 'Form.io Dashboard'
                    </Link>  
                </Grid>     
                <Grid item xs={12} sm={3}>
                    <TextField
                        id="liveEndpoint"
                        variant="filled"
                        fullWidth
                        label="Live Endpoint"
                        value={props.configuration.formioLiveEndpoint ? props.configuration.formioLiveEndpoint : ""}
                        InputProps={{
                            readOnly: true
                        }}
                    />              
                </Grid>
            </Grid>

            { props.forms &&
                <Grid item xs={12} style={{overflow: "auto"}}>
                    
                    <TableContainer>
                        <Table 
                            className={classes.table}
                            size="small"
                            aria-labelledby="forms"
                            aria-label="enhanced table">
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={props.forms.length}
                                    headCells={headCells}
                                    />
                                <TableBody>
                                    {stableSort(props.forms, getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(form => {
                                            return (
                                                <TableRow key={form.id}  className={classes.tableRow}>
                                                    <TableCell component="th" scope="row" >{form.id}</TableCell>
                                                    <TableCell component="th" scope="row" >{form.name}</TableCell>
                                                    <TableCell component="th" scope="row" >{form.apiPath}</TableCell>
                                                    <TableCell component="th" scope="row" ><EditButton pid={form.id} handleEdit={onEdit} /></TableCell>
                                                    <TableCell component="th" scope="row" ><DeleteButton pid={form.id} handleDelete={onDelete} /></TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                        </Table>                    
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25,100]}
                        component="div"
                        count={props.forms.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Grid>   
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete Form"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete selected form?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant="contained" onClick={handleConfirmDelete} color="primary">
                    Yes
                </Button>
                <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
                    No
                </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );    
}

const mapStateToProps = state => {

    return {
        forms: getFormsSelector(state),
        configuration: getConfiguration(state)
    };

};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ getForms, clearForm, deleteForm, getSettings }, dispatch)   
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserManagement)