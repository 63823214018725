import React from "react";
import auth from "../services/authService";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, render, ...rest }) => {

  const jwtTokenExpired = () => {

    const jwt = auth.getCurrentUser();
    var current_time = new Date().getTime() / 1000;
    if (current_time > jwt.exp) { 
      return true;
    } 
    return false;
    
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (!auth.getCurrentUser() || jwtTokenExpired())
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
