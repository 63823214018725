import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "../../../utils/history";
import { isPatient, handleSelectChange, isAdministratorOrManager, roles } from "../../../utils/constants";
import {
  Grid,
  makeStyles,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { userSchema } from "./user-schema";
import { toast } from "react-toastify";
import {
  getUsers,
  getUser,
  getUserSelector,
  getUsersSelector,
  getRoles,
  getRolesSelector,
  getEmailTemplates,
  saveUser,
  getEmailTemplatesSelector,
  getUserPackagesSelector,
  getPackages,
  getPackagesSelector,
  getConsentPackageIdsSelector,
  getLocations,
  getLocationsSelector,
  getUserSecondaryLocations
} from "../../../store/admin";
import PackageInfo from "./package-info/package-info-view";
import SecondaryLocations from "./secondary-locations/secondary-locations-view";
import { getCurrentUser, getUserPermissions } from "../../../store/user";

const useStyles = makeStyles((theme) => ({
  backArrowOutter: theme.backArrowOutter,
  darkArrow: theme.darkArrow,
  form: theme.form,
  checkbox: theme.checkabox,
  multiSelect: theme.multiSelect,
  multiSelectEmpty: theme.multiSelectEmpty,
}));

const User = ({ ...props }) => {
  const classes = useStyles();
  const {
    match: { params },
    user = {},
  } = props;
  const [showPackageInfo, setShowPackageInfo] = useState(false);
  const [showSecondaryLocations, setShowSecondaryLocations] = useState(false);
  const [disabledSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    const init = async () => {
      const {
        getUser,
        getUsers,
        getRoles,
        getPackages,
        getEmailTemplates,
        getLocations,
      } = props;
      const {
        match: { params },
      } = props;

      await getEmailTemplates();
      await getPackages();
      await getUsers();
      await getRoles();
      await getLocations();

      if (params.id !== "new") {
        var {
          payload: { user },
        } = await getUser(params.id);
        
        setShowPackageInfo(isPatient(user.roleId));
        setShowSecondaryLocations(!isPatient(user.roleId));
      }
    };
    init();
  }, []);

  const handleSelectRoleChange = (e, setFieldValue, name) => {

    e.preventDefault();

    if (isPatient(e.target.value)) {

      setShowPackageInfo(true);
      setFieldValue("checked", true);
      setShowSecondaryLocations(false);
      setFieldValue("secondaryLocationIds",[])

    } else {

      setShowPackageInfo(false);
      setFieldValue("checked", false);
      setShowSecondaryLocations(true);
    }

    setFieldValue(name, e.target.value);
  };

  const onCheck = (e, setFieldValue, name) => {
    setFieldValue(name, e.target.checked);
  };

  const handleLocationChange = (e, setFieldValue, name) => {

    e.preventDefault();
    setFieldValue(name, e.target.value);
    setFieldValue("secondaryLocationIds",[]);

  };

  const handleSave = async (values) => {
    setDisableSubmit(true);
    const {
      match: { params },
      saveUser,
      users,
    } = props;

    const user = {
      id: params.id === "new" ? 0 : params.id,
      username: values.username,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      roleId: values.roleId,
      locationId: values.locationId
    };

    const exists = users.find((u) => u.username === user.username);

    if (exists && exists.id !== parseInt(params.id)) {

      toast.error(`User already exists with username ${user.username}`);
      setDisableSubmit(false);
      return;

    } else {
      var userPackage = {
        user,
        packageIds: values.packageIds,
        emailInfo: {
          sendEmail: values.checked,
          emailTemplateId: values.emailTemplateId,
        },
        secondaryLocationIds: values.secondaryLocationIds
      };

      await saveUser(userPackage);
      history.push("/user-management");
    }
  };

  var filteredRoles = props.roles;
  if(!isAdministratorOrManager(props.currentUser.roleId)){
    filteredRoles = filteredRoles.filter(r => r.roleId != roles.administrator && r.roleId != roles.manager)
  }
  
  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.backArrowOutter}>
          <span
            onClick={(e) => history.push("/user-management")}
            className={classes.darkArrow}
          >
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Back to User
            Management{" "}
          </span>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            {params.id === "new" ? "New User" : `Edit User ${params.id} `}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              username: user.username ? user.username : "",
              password: user.password ? user.password : "",
              firstName: user.firstName ? user.firstName : "",
              lastName: user.lastName ? user.lastName : "",
              locationId: user.locationId
                ? user.locationId
                : props.currentUser
                ? props.currentUser.locationId
                : 0,
              roleId: user.roleId ? user.roleId : 0,
              emailTemplateId: 0,
              checked: false,
              packageIds: props.userPackages
                ? props.userPackages
                : props.consentPackageIds,
              secondaryLocationIds: props.secondaryLocations ? props.secondaryLocations : []
            }}
            validationSchema={userSchema}
            onSubmit={(values) => {
              handleSave(values);
            }}
            render={({
              values,
              setFieldValue,
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
              touched,
              ...formProps
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      error={errors.username && touched.username ? true : false}
                      name="username"
                      type="text"
                      label="Username/Email"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                    />
                    <ErrorMessage name="username">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      error={
                        errors.firstName && touched.firstName ? true : false
                      }
                      name="firstName"
                      type="firstName"
                      label="First Name"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    <ErrorMessage name="firstName">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      error={errors.lastName && touched.lastName ? true : false}
                      name="lastName"
                      type="lastName"
                      label="Last Name"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    <ErrorMessage name="lastName">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      error={errors.password && touched.password ? true : false}
                      name="password"
                      type="password"
                      label="Password"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <ErrorMessage name="password">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>
                  {props.currentUser && (
                    <Grid item xs={12} sm={4}>
                      <FormControl className={classes.select} fullWidth>
                        <InputLabel htmlFor="locationId">Primary Location</InputLabel>
                        <Select
                          error={
                            errors.locationId && touched.locationId
                              ? true
                              : false
                          }
                          value={values.locationId}
                          onChange={(e) =>
                            handleLocationChange(e, setFieldValue, "locationId")
                          }
                          inputProps={{
                            name: "locationId",
                            id: "locationId",
                          }}
                        >
                          {props.locations &&
                            props.locations.map((location) => (
                              <MenuItem key={location.id} value={location.id}>
                                {location.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <ErrorMessage name="locationId">
                        {(msg) => (
                          <div className="error error-message">{msg}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={4}>
                    <FormControl className={classes.select} fullWidth>
                      <InputLabel htmlFor="roleId">Role</InputLabel>
                      <Select
                        error={errors.roleId && touched.roleId ? true : false}
                        value={values.roleId}
                        onChange={(e) =>
                          handleSelectRoleChange(e, setFieldValue, "roleId")
                        }
                        inputProps={{
                          name: "roleId",
                          id: "roleId",
                        }}
                      >
                        {filteredRoles &&
                          filteredRoles.map((filteredRole) => (
                            <MenuItem key={filteredRole.roleId} value={filteredRole.roleId}>
                              {filteredRole.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <ErrorMessage name="roleId">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12}>
                    {showPackageInfo && (
                      <PackageInfo
                        emailTemplates={props.emailTemplates}
                        packages={props.packages}
                        errors={errors}
                        touched={touched}
                        values={values}
                        setFieldValue={setFieldValue}
                        classes={classes}
                        handleCheck={onCheck}
                        checked={values.checked}
                        handleSelectChange={handleSelectChange}
                      />
                    )}
                    {showSecondaryLocations && isAdministratorOrManager(props.currentUser.roleId) && (
                      <SecondaryLocations
                        locations={props.locations}
                        values={values}
                        setFieldValue={setFieldValue}
                        classes={classes}
                        handleSelectChange={handleSelectChange}

                      ></SecondaryLocations>
                    )}
                  </Grid>
                  <Grid item xs={12} align="right">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={disabledSubmit}
                    >
                      Save User
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    roles: getRolesSelector(state),
    user: getUserSelector(state),
    users: getUsersSelector(state),
    emailTemplates: getEmailTemplatesSelector(state),
    packages: getPackagesSelector(state),
    consentPackageIds: getConsentPackageIdsSelector(state),
    userPackages: getUserPackagesSelector(state),
    locations: getLocationsSelector(state),
    currentUser: getCurrentUser(state),
    secondaryLocations: getUserSecondaryLocations(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        getUsers,
        getUser,
        getRoles,
        saveUser,
        getEmailTemplates,
        getPackages,
        getLocations,
        getCurrentUser,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
