import React, { Fragment } from "react";

import { Typography } from "@material-ui/core";

const NoPackagesAssigned = ({ usersPackage }) => {
  return (
    <Fragment>
      {usersPackage.userPackages.length === 0 && (
        <Typography>No package(s) have been assigned to patient.</Typography>
      )}
    </Fragment>
  );
};

export default NoPackagesAssigned;
