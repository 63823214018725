import React, { Fragment } from "react";
import { Tooltip, IconButton } from "@material-ui/core";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const ViewButton = ({ ...props }) => {
  const { handelToggleView, userId, packageId, forms } = props;
  return (
    <Fragment>
      {forms && forms.length > 0 && (
        <Tooltip
          title="Hide Package Details"
          onClick={(e) => handelToggleView(e, packageId, userId, true)}
        >
          <IconButton aria-label="hidePackageDetails">
            <VisibilityOffIcon />
          </IconButton>
        </Tooltip>
      )}
      {forms && forms.length === 0 && (
        <Tooltip
          title="View Package Details"
          onClick={(e) => handelToggleView(e, packageId, userId, false)}
        >
          <IconButton aria-label="viewPackageDetails">
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      )}
    </Fragment>
  );
};

export default ViewButton;
