import React from "react";
import { Grid } from "@material-ui/core";
import { Form } from "react-formio";
import emailService from "../../services/emailService";
import { covidRapidTests } from "../../utils/constants";

const COVIDRapidTest = () => {
  const handleSubmitDone = async (values) => {

    var cleared = window.document.getElementsByClassName("cleared")[0];

    if (cleared) {
      values.data.result = true;
    }    
    
    var submissionUrl = `${covidRapidTests.submissionUrlBase}/${values.form}/submission/${values._id}`;

    var emailData = {
      tos: [values.data.email, values.data.manager, covidRapidTests.hrEmail],
      data: JSON.stringify({...values.data, submissionUrl}),
      templateId: cleared
        ? covidRapidTests.clearedEmailTemplateId
        : covidRapidTests.failedEmailTemplateId,
    };

    emailService.send(emailData);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Form
          name="form"
          src={covidRapidTests.formUrl}
          onSubmitDone={(result) => handleSubmitDone(result)}
        />
      </Grid>
    </Grid>
  );
};

export default COVIDRapidTest;
