import axios from "axios";
import { history } from "../utils/history";
import { toast } from "react-toastify";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    toast.error("An unexpected error occured.");
  }

  if (error.response.status === 401 || error.response.status === 403 ) {
    history.push("/login");
  }


  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["X-auth-token"] = jwt;
  axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
}

function setFormioJwt(jwt) {
  axios.defaults.headers.common["x-jwt-token"] = jwt;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  setFormioJwt,
};
