import http from "./httpService";
import auth from "./authService";

http.setJwt(auth.getFormioJwt())

export function testForm(apiEndPoint, name) {
  return http.get(`${apiEndPoint}/${name}`);
}

export function getForm(apiEndPoint, name) {
  return http.get(`${apiEndPoint}/${name}`);
}

export function getToken(apiEndPoint) {
  return http.get(`${apiEndPoint}/token`);
}

export default {
  testForm,
  getForm,
  getToken
};
