import React, { Fragment } from "react";
import { Tooltip, IconButton } from "@material-ui/core";

import CheckBoxOutlineBlankRoundedIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";

const ExportedButton = ({ ...props }) => {
  const { handleToggleExport, isExported, packageId, userId } = props;

  return (
    <Fragment>
      {isExported && (
        <Tooltip
          title="Mark as Not Exported"
          onClick={(e) => handleToggleExport(e, packageId, userId)}
        >
          <IconButton aria-label="notExported">
            <CheckBoxRoundedIcon />
          </IconButton>
        </Tooltip>
      )}
      {!isExported && (
        <Tooltip
          title="Mark as Exported"
          onClick={(e) => handleToggleExport(e, packageId, userId)}
        >
          <IconButton aria-label="exported">
            <CheckBoxOutlineBlankRoundedIcon />
          </IconButton>
        </Tooltip>
      )}
    </Fragment>
  );
};

export default ExportedButton;
