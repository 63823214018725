import React, { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { history } from "../../utils/history";
import {
  locations,
  canManageRoles,
  canManagePermissions,
  canManageUsers,
} from "../../utils/constants";
import {
  getNameFromRoleId,
  getNameFromLocationId,
} from "../../utils/constants";
import {
  makeStyles,
  Grid,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import {
  getUsersSelector,
  getUsers,
  clearUser,
  deleteUser,
  getRoles,
  getRolesSelector,
  getLocationsSelector,
  getLocations,
  getUserFilterSelector,
  clearUserFilter,
  updateUserFilterItem,
  getFilteredLocationsSelector,
} from "../../store/admin";
import { getUserPermissions, setUserFromToken } from "../../store/user";
import { TablePaginationActions } from "../shared/pagination";
import {
  EnhancedTableHead,
  stableSort,
  getSorting,
} from "../shared/tableHeader";
import EditButton from "../shared/edit-button";
import DeleteButton from "../shared/delete-button/delete-button-view";
import auth from "../../services/authService";

const useStyles = makeStyles((theme) => ({
  button: theme.button,
}));

const UserManagement = ({ ...props }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);

  useEffect(() => {
    const init = async () => {
      const {
        getUsers,
        clearUser,
        getRoles,
        getLocations,
        setUserFromToken,
        updateUserFilterItem,
      } = props;
      await getRoles();
      await getLocations();
      await getUsers();
      clearUser();

      const token = auth.getCurrentUser();

      if (token) {
        const {
          payload: { rolePermissions },
        } = await setUserFromToken(token);

        updateUserFilterItem("locationId", parseInt(token.groupsid));
      }
    };
    init();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSelectedUserId(0);
  };

  const onDelete = (id) => {
    setSelectedUserId(id);
    setOpen(true);
  };

  const handleConfirmDelete = async () => {
    const { deleteUser, clearUser } = props;
    await deleteUser(selectedUserId);
    clearUser();
    setOpen(false);
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (e, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const onEdit = (id) => {
    history.push(`/user/${id}`);
  };

  const handleSearchChange = (e, name) => {
    const { updateUserFilterItem } = props;
    updateUserFilterItem(name, e.target.value);
  };

  const doClear = async () => {
    const { clearUserFilter } = props;
    const token = await auth.getCurrentUser();
    await clearUserFilter(parseInt(token.groupsid));
  };

  const headCells = [
    { id: "id", numeric: false, disablePadding: false, label: "Id" },
    {
      id: "username",
      numeric: false,
      disablePadding: false,
      label: "Username",
    },
    {
      id: "firstName",
      numeric: false,
      disablePadding: false,
      label: "First Name",
    },
    {
      id: "lastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
    },
    {
      id: "password",
      numeric: false,
      disablePadding: false,
      label: "Password",
    },
    { id: "roleId", numeric: false, disablePadding: false, label: "Role" },
    {
      id: "locationId",
      numeric: false,
      disablePadding: false,
      label: "Location",
    },
    { id: "edit", numeric: false, disablePadding: false, label: "" },
    { id: "delete", numeric: false, disablePadding: false, label: "" },
  ];

  const { filter, rolePermissions } = props;
  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" gutterBottom>
            User Management
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            type="button"
            color="secondary"
            className={classes.button}
            onClick={(e) => history.push(`/user/new`)}
          >
            New User
          </Button>
        </Grid>
        {rolePermissions && canManageRoles(rolePermissions) && (
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              type="button"
              color="primary"
              className={classes.button}
              onClick={(e) => history.push(`/roles`)}
            >
              Manage Roles
            </Button>
          </Grid>
        )}
        {rolePermissions && canManagePermissions(rolePermissions) && (
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              type="button"
              color="primary"
              className={classes.button}
              onClick={(e) => history.push(`/permissions`)}
            >
              Manage Permissions
            </Button>
          </Grid>
        )}
      </Grid>
      {filter && (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <TextField
              name="search"
              type="text"
              label="Search by username/email"
              margin="normal"
              fullWidth
              onChange={(e) => handleSearchChange(e, "username")}
              value={filter.username}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="search"
              type="text"
              label="Search by first name"
              margin="normal"
              fullWidth
              onChange={(e) => handleSearchChange(e, "firstName")}
              value={filter.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="search"
              type="text"
              label="Search by last name"
              margin="normal"
              fullWidth
              onChange={(e) => handleSearchChange(e, "lastName")}
              value={filter.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.select} fullWidth>
              <InputLabel htmlFor="locationId">Location</InputLabel>
              <Select
                value={filter.locationId}
                onChange={(e) => handleSearchChange(e, "locationId")}
                inputProps={{
                  name: "locationId",
                  id: "locationId",
                }}
              >
                {props.locations &&
                  props.locations.map((location) => (
                    <MenuItem key={location.id} value={location.id}>
                      {location.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={doClear}
          >
            Clear
          </Button>
        </Grid>
      </Grid>

      {props.users && (
        <Grid item xs={12} style={{ overflow: "auto" }}>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-labelledby="users"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={props.users.length}
                headCells={headCells}
              />
              <TableBody>
                {stableSort(props.users, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => {
                    return (
                      <TableRow key={user.id} className={classes.tableRow}>
                        <TableCell component="th" scope="row">
                          {user.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {user.username}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {user.firstName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {user.lastName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          *****
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {props.roles &&
                            getNameFromRoleId(user.roleId, props.roles)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {props.locations &&
                            getNameFromLocationId(
                              user.locationId,
                              props.locations
                            )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <EditButton pid={user.id} handleEdit={onEdit} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <DeleteButton pid={user.id} handleDelete={onDelete} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={props.users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Grid>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Delete User"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete selected user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleConfirmDelete}
            color="primary"
          >
            Yes
          </Button>
          <Button
            variant="contained"
            onClick={handleClose}
            color="primary"
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    rolePermissions: getUserPermissions(state),
    users: getUsersSelector(state),
    roles: getRolesSelector(state),
    locations: getFilteredLocationsSelector(state),
    filter: getUserFilterSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        getUsers,
        clearUser,
        deleteUser,
        getRoles,
        getLocations,
        updateUserFilterItem,
        clearUserFilter,
        setUserFromToken,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
