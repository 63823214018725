import React, { Fragment } from "react";
import { getNameFromPackageId, MenuProps } from "../../../../utils/constants";
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Input,
  Chip,
} from "@material-ui/core";
import { ErrorMessage } from "formik";

const PackageInfo = ({ ...props }) => {
  const {
    emailTemplates,
    packages,
    errors,
    touched,
    values,
    handleSelectChange,
    setFieldValue,
    classes,
    checked,
    handleCheck,
    consentPackages,
  } = props;

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Package Info
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => handleCheck(e, setFieldValue, "checked")}
                name="sendEmail"
                color="primary"
              />
            }
            label="Send email automatically?"
          />
        </Grid>
        <Fragment>
          {checked && (
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.select} fullWidth>
                <InputLabel htmlFor="emailTemplateId">
                  Email Templates
                </InputLabel>
                <Select
                  error={
                    errors.emailTemplateId && touched.emailTemplateId
                      ? true
                      : false
                  }
                  value={values.emailTemplateId}
                  onChange={(e) =>
                    handleSelectChange(e, setFieldValue, "emailTemplateId")
                  }
                  inputProps={{
                    name: "emailTemplateId",
                    id: "emailTemplateId",
                  }}
                >
                  {emailTemplates &&
                    emailTemplates.map((emailTemplate) => (
                      <MenuItem key={emailTemplate.id} value={emailTemplate.id}>
                        {emailTemplate.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <ErrorMessage name="emailTemplateId">
                {(msg) => <div className="error error-message">{msg}</div>}
              </ErrorMessage>
            </Grid>
          )}
          <Grid item xs={12} sm={4}>
            <FormControl
              className={
                values.packageIds.length > 0
                  ? classes.multiSelect
                  : classes.multiSelectEmpty
              }
            >
              <InputLabel id="packageIds">Packages</InputLabel>
              <Select
                id="packageIds"
                error={errors.packageIds && touched.packageIds ? true : false}
                multiple
                value={values.packageIds}
                onChange={(e) =>
                  handleSelectChange(e, setFieldValue, "packageIds")
                }
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value, index) => (
                      <Chip
                        key={`${value}-${index}`}
                        label={getNameFromPackageId(value, packages)}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {packages &&
                  packages.map((_package) => (
                    <MenuItem key={_package.id} value={_package.id}>
                      {_package.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <ErrorMessage name="packageIds">
              {(msg) => <div className="error error-message">{msg}</div>}
            </ErrorMessage>
          </Grid>
        </Fragment>
      </Grid>
    </Fragment>
  );
};

export default PackageInfo;
