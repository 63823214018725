import React, { Fragment } from "react";
import Moment from "react-moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  Paper,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LazyLoad from "react-lazyload";

import { getUser, getUserSelector } from "../../../store/admin";
import PackageStatus from "../../shared/package-status";
import Header from "./header";
import Loading from "../../shared/loading/loading-view";
import NoPackagesAssignedView from "./no-packages-assigned";
import MenuBarView from "./menu-bar";

const useStyles = makeStyles((theme) => ({
  button: theme.button,
  paper: theme.paper,
  indent: theme.indent,
  expansionPanel: theme.expansionPanel,
}));

const UsersPackages = ({ ...props }) => {
  const { usersPackage, setSelectedUserPackage, setOpen, user } = props;
  const classes = useStyles();

  const onSendReminder = async (usersPackage) => {
    const { getUser } = props;
    setSelectedUserPackage(usersPackage);
    setOpen(true);
    await getUser(usersPackage.user.id);
  };

  return (
    <Grid item xs={12}>
      <ExpansionPanel
        defaultExpanded={user && user.id === usersPackage.user.id}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container>
            <Grid item xs>
              <Typography variant="h6">{usersPackage.user.username}</Typography>
            </Grid>
            <Grid item xs={12} sm={1}>
              <PackageStatus
                isComplete={usersPackage.userPackages.every(
                  (up) => up.isComplete
                )}
              />
            </Grid>
          </Grid>{" "}
        </ExpansionPanelSummary>
        <LazyLoad placeholder={<Loading />}>
          <ExpansionPanelDetails className={classes.expansionPanel}>
            <Grid item xs={12}>
              <Typography>
                {usersPackage.user.firstName} {usersPackage.user.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Typography>
                        <b>Last Email:</b>
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      {usersPackage.user.lastEmailDate && (
                        <Moment
                          date={usersPackage.user.lastEmailDate}
                          format="YYYY-MM-DD h:mm a"
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    disabled={usersPackage.userPackages.every(
                      (up) => up.isComplete === true
                    )}
                    color="secondary"
                    onClick={(e) => onSendReminder(usersPackage)}
                  >
                    Send Reminder
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>Assigned Package(s):</Typography>
            </Grid>
            <NoPackagesAssignedView usersPackage={usersPackage} />
            {usersPackage.userPackages &&
              usersPackage.userPackages.map((userPackage, index) => (
                <Paper className={classes.paper} key={index}>
                  <Grid container spacing={2}>
                    <Header
                      userPackage={userPackage}
                      userId={usersPackage.user.id}
                    />
                  </Grid>
                  {userPackage.forms &&
                    userPackage.forms.map((formDetail, index) => (
                      <Fragment key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography className={classes.indent}>
                              {formDetail.form.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container spacing={2}>
                              <MenuBarView
                                formDetail={formDetail}
                                userPackage={userPackage}
                                usersPackage={usersPackage}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Fragment>
                    ))}
                </Paper>
              ))}
          </ExpansionPanelDetails>
        </LazyLoad>
      </ExpansionPanel>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    user: getUserSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        getUser,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPackages);
