import React, { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form } from "react-formio";
import {
  makeStyles,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import {
  getPackage,
  getPackageSelector,
  getLocation,
  getLocationSelector,
  getUser,
  getFormsSubmissions,
  getFormsSubmissionsSelector,
} from "../../store/admin";
import { Helmet } from "react-helmet";
import logo from "../../Images/medsleep-logo.jpg";
import { downloadAttachment } from "../../utils/file-helper";

const useStyles = makeStyles((theme) => ({
  button: theme.button,
  paper: theme.paper,
  exportLogo: theme.exportLogo,
  header: {
    marginBottom: "1rem",
  },
  linearProgress: theme.linearProgress,
}));

const ExportAll = ({ ...props }) => {
  const classes = useStyles();
  const {
    match: { params },
    getLocation,
    getUser,
  } = props;
  const { userId, packageId } = params;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      const { getFormsSubmissions, getPackage } = props;
      const {
        payload: { user },
      } = await getUser(userId);
      await getLocation(user.locationId);
      await getPackage(packageId);

      var patientSubmission = {
        userId,
        packageId,
      };

      const {
        payload: { formsSubmissions },
      } = await getFormsSubmissions(patientSubmission);

      formsSubmissions.forEach((item) => {
        if (item.patientSubmission) {
          var data = {
            data: JSON.parse(item.patientSubmission.submission),
          };
          downloadAttachment(data.data);
        }
      });
    };
    init();
  }, []);

  const { location, formsSubmissions, _package } = props;
  return (
    <Fragment>
      <Helmet>{_package && <title>{_package.name}</title>}</Helmet>
      {location && (
        <Fragment>
          {loading && (
            <Fragment>
              <Typography>Exporting...</Typography>
              <LinearProgress className={classes.linearProgress} />
            </Fragment>
          )}
          <Grid container spacing={4} className={classes.header}>
            <Grid item xs={12} sm={6}>
              <img
                src={logo}
                className={classes.exportLogo}
                alt="MedSleep Logo"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">{location.name}</Typography>
              <Typography>{location.address}</Typography>
              <Typography>{location.telephone}</Typography>
              <Typography>{location.fax}</Typography>
              <Typography>{location.email}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {formsSubmissions &&
              formsSubmissions.map((formSubmission, index) => (
                <Form
                  key={index}
                  src={formSubmission.formURL}
                  options={{ readOnly: false }}
                  submission={
                    formSubmission.patientSubmission &&
                    formSubmission.patientSubmission.submission
                      ? {
                          data: JSON.parse(
                            formSubmission.patientSubmission.submission
                          ),
                        }
                      : null
                  }
                  onRender={function () {
                    setLoading(true);
                    if (index === formsSubmissions.length - 1) {
                      document
                        .getElementsByTagName("nav")[0]
                        .setAttribute("style", "display:none");
                      setTimeout(function () {
                        setLoading(false);
                        window.document
                          .getElementsByClassName("btn")
                          .forEach((e) =>
                            e.setAttribute("style", "display:none")
                          );
                        window.print();
                      }, 5000);
                    }
                  }}
                />
              ))}
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    _package: getPackageSelector(state),
    location: getLocationSelector(state),
    formsSubmissions: getFormsSubmissionsSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      { getLocation, getUser, getFormsSubmissions, getPackage },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportAll);
