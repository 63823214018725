import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../../../../utils/history';
import { getNameFromPermissionId, handleSelectChange, MenuProps } from '../../../../utils/constants';

import { 
    Grid,
    makeStyles,
    Button,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    Select,
    Chip,
    MenuItem,
    Input
    } from '@material-ui/core';
import {Formik, ErrorMessage} from 'formik';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { roleSchema } from './role-schema';
import { toast } from 'react-toastify';
import {
    getRole,
    getRoles,
    getRoleSelector,
    getRolesSelector,
    saveRole,
    getPermissionsSelector,
    getPermissions,
    getRolePermissionsSelector} from '../../../../store/admin';

const useStyles = makeStyles(theme => ({

    backArrowOutter: theme.backArrowOutter,
    darkArrow: theme.darkArrow,
    form: theme.form,
    select: theme.select,
    chips: theme.chips,
    chip: theme.chip,
    multiSelectEmpty: theme.multiSelectEmpty,    
    multiSelect: theme.multiSelect

}))

const Role = ({ ...props }) => {

    const classes = useStyles();      
    const { match: { params }, role={} } = props;

    useEffect(() => {

        const init = async () => {

            const { getRole, getRoles, getPermissions } = props;
            const { match: { params } } = props;


            await getRoles();
            await getPermissions();
           
            if(params.id !== "new")
                await getRole(params.id);


        }
        init();   
        
    }, []);


    
    const handleSave = async (values) => {

        const { match: { params }, saveRole, roles } = props;

        const role = {
            id: (params.id === "new") ? 0 : params.id,
            roleId: values.roleId,
            name: values.name,
        }

        const exists = roles.find(r => r.roleId === parseInt(role.roleId));

        if(exists && exists.id !== parseInt(params.id)){
            toast.error(`Role already exists with role id ${role.roleId}`);
            return;
        }
        else{

            const rolePermission = {
                role,
                permissionIds: values.permissionIds
            };
            await saveRole(rolePermission);    
            history.push("/roles");    
        }

    }

    return(
        <Fragment>
            <Grid container spacing={4}>
                <Grid item xs={12} className={classes.backArrowOutter}>
                    <span onClick={e => history.push("/roles")} className={classes.darkArrow}>
                        <FontAwesomeIcon icon={faArrowLeft}>
                            </FontAwesomeIcon> Back to Roles </span>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom>
                        {params.id === "new" ? "New Role" : `Edit Role ${params.id} `}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={
                            { 
                                roleId: role.roleId ? role.roleId : "",
                                name: role.name ? role.name : "",
                                permissionIds: props.rolePermissions ? props.rolePermissions : []
                            }
                        }
                        validationSchema={roleSchema}

                        onSubmit={(values) => {
                            handleSave(values);
                        }}

                        render={({ values, setFieldValue, handleChange, handleSubmit, handleBlur, errors, touched, ...formProps }) => (
                            <form onSubmit={handleSubmit} className={classes.form}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            error={(errors.roleId) && touched.roleId ? true : false}
                                            name="roleId"
                                            type="text"
                                            label="Role Id"
                                            margin="normal"
                                            fullWidth
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.roleId}
                                        />
                                        <ErrorMessage name="roleId">{msg => <div className="error error-message">{msg}</div>}</ErrorMessage>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            error={(errors.name && touched.name) ? true : false}
                                            name="name"
                                            type="name"
                                            label="Name"
                                            margin="normal"
                                            fullWidth
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        />
                                        <ErrorMessage name="name">{msg => <div className="error error-message">{msg}</div>}</ErrorMessage>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl className={values.permissionIds.length > 0 ? classes.multiSelect : classes.multiSelectEmpty}>
                                            <InputLabel id="permissionIds">Permissions</InputLabel>
                                                <Select
                                                    id="permissionIds"
                                                    error={(errors.name && touched.name) ? true : false}
                                                    multiple
                                                    value={values.permissionIds}
                                                    onChange={e => handleSelectChange(e,setFieldValue,"permissionIds")}
                                                    input={<Input id="select-multiple-chip" />}
                                                    renderValue={(selected) => (

                                                        <div className={classes.chips}>
                                                            {selected.map((value, index) => (
                                                                <Chip key={`${value}-${index}`} label={getNameFromPermissionId(value,props.permissions)} className={classes.chip} />
                                                            ))}
                                                        </div>
                                                )}MenuProps={MenuProps}>
                                                    {props.permissions && props.permissions.map((permission) => (
                                                        <MenuItem 
                                                            key={permission.id} 
                                                            value={permission.permissionId} 
                                                            >
                                                                {permission.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                        </FormControl>
                                        <ErrorMessage name="permissionIds">{msg => <div className="error error-message">{msg}</div>}</ErrorMessage>
                                    </Grid>
                                    <Grid item xs={12} align="right">
                                        <Button type="submit" variant="contained" color="primary">Save Role</Button>
                                    </Grid>
                                    
                                </Grid>
                            </form>
                        )}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );    
}

const mapStateToProps = (state) => {

    return {
        roles: getRolesSelector(state),
        role: getRoleSelector(state),
        permissions: getPermissionsSelector(state),
        rolePermissions: getRolePermissionsSelector(state)
    };

};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({getRoles, getRole, saveRole, getPermissions}, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Role)

