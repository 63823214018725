import React, { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showResult } from "../../utils/constants";

import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import LazyLoad from "react-lazyload";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  getUsersPackagesSelector,
  getUsersPackages,
  clearSubmission,
  getLocations,
  getEmailTemplates,
  getEmailTemplatesSelector,
  getFilteredLocationsSelector,
  clearUsersPackages,
  sendReminderEmail,
} from "../../store/admin";
import authService from "../../services/authService";
import UserPackages from "./user-packages";
import Loading from "../shared/loading/loading-view";

const useStyles = makeStyles((theme) => ({
  button: theme.button,
  radioGroup: theme.radioGroup,
}));

const ApplicationManagement = ({ ...props }) => {
  const { usersPackages, locations, emailTemplates } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(2);
  const [selectedUserPackage, setSelectedUserPackage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [syncResult, setSyncResult] = useState({});
  const [filter, setFilter] = useState({
    username: "",
    firstName: "",
    lastName: "",
    locationId: 0,
    show: "",
  });

  useEffect(() => {
    const init = async () => {
      const { clearSubmission, getLocations, getEmailTemplates } = props;
      const token = authService.getCurrentUser();
      setFilter({ ...filter, locationId: parseInt(token.groupsid) });
      await getLocations();
      await getEmailTemplates();
      clearSubmission();
    };
    init();
  }, []);

  const handleSearchChange = (e, name) => {
    setFilter({ ...filter, [name]: e.target.value });
  };

  const doClear = async () => {
    const { clearUsersPackages } = props;
    const token = authService.getCurrentUser();

    const reset = {
      username: "",
      firstName: "",
      lastName: "",
      locationId: parseInt(token.groupsid),
      show: "",
      take: 100,
      skip: 0,
    };

    setFilter(reset);
    clearUsersPackages();
  };

  const doSearch = async () => {
    const { getUsersPackages, clearUsersPackages } = props;

    setShowLoader(true);
    await clearUsersPackages();

    let slice = 3000;
    let syncing = true;
    let skip = 0;
    let take = slice;

    while (syncing) {
      const result = await getUsersPackages({ ...filter, skip, take });
      setSyncResult({ ...result, skip });
      syncing = result.remainingRecords;
      skip = skip + slice;
    }

    setShowLoader(false);
    setSyncResult({});
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUserPackage(null);
  };

  const handleSendEmail = async () => {
    setOpen(false);
    const { sendReminderEmail } = props;
    const { user } = selectedUserPackage;

    const packageIds = selectedUserPackage.userPackages
      .filter((up) => up.isComplete == false)
      .map((up) => up.package.id);

    var userPackage = {
      user,
      packageIds,
      emailInfo: {
        sendEmail: true,
        emailTemplateId: selectedEmailTemplate,
      },
    };

    sendReminderEmail(userPackage);
    setSelectedUserPackage(null);
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Application Management
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Patient applications are displayed below. To filter patients please
          fill in filters below.
        </Typography>
      </Grid>
      {filter && (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <TextField
              name="search"
              type="text"
              label="Search by username/email"
              margin="normal"
              fullWidth
              onChange={(e) => handleSearchChange(e, "username")}
              value={filter.username}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="search"
              type="text"
              label="Search by first name"
              margin="normal"
              fullWidth
              onChange={(e) => handleSearchChange(e, "firstName")}
              value={filter.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="search"
              type="text"
              label="Search by last name"
              margin="normal"
              fullWidth
              onChange={(e) => handleSearchChange(e, "lastName")}
              value={filter.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.select} fullWidth>
              <InputLabel htmlFor="locationId">Location</InputLabel>
              <Select
                value={filter.locationId}
                onChange={(e) => handleSearchChange(e, "locationId")}
                inputProps={{
                  name: "locationId",
                  id: "locationId",
                }}
              >
                {locations &&
                  locations.map((location) => (
                    <MenuItem key={location.id} value={location.id}>
                      {location.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Show</FormLabel>
              <RadioGroup
                aria-label="show"
                name="show"
                value={filter.show}
                className={classes.radioGroup}
                onChange={(e) => handleSearchChange(e, "show")}
              >
                <FormControlLabel
                  value={showResult.completed}
                  control={<Radio />}
                  label="Completed"
                />
                <FormControlLabel
                  value={showResult.notCompleted}
                  control={<Radio />}
                  label="Not Completed"
                />
                <FormControlLabel
                  value={showResult.exported}
                  control={<Radio />}
                  label="Exported"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={doSearch}
            disabled={showLoader}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={doClear}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {showLoader && (
            <div>
              <Typography component="div">
                Searching <b>{syncResult.skip}</b> of <i>{syncResult.total}</i>{" "}
                records... <CircularProgress size={25} />
              </Typography>
            </div>
          )}
        </Grid>
        {usersPackages &&
          usersPackages.map((usersPackage) => (
            <LazyLoad key={usersPackage.user.id} placeholder={<Loading />}>
              <UserPackages
                key={usersPackage.user.id}
                usersPackage={usersPackage}
                setSelectedUserPackage={setSelectedUserPackage}
                setOpen={setOpen}
              />
            </LazyLoad>
          ))}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Send Reminder"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select an email template to send to patient.
          </DialogContentText>
          <DialogContent>
            <FormControl className={classes.select} fullWidth>
              <InputLabel htmlFor="emailTemplateId">Email Templates</InputLabel>
              <Select
                fullWidth
                value={selectedEmailTemplate}
                onChange={(e) => setSelectedEmailTemplate(e.target.value)}
                inputProps={{
                  name: "emailTemplateId",
                  id: "emailTemplateId",
                }}
              >
                {emailTemplates &&
                  emailTemplates.map((emailTemplate) => (
                    <MenuItem key={emailTemplate.id} value={emailTemplate.id}>
                      {emailTemplate.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSendEmail} color="primary">
            Send Email
          </Button>
          <Button
            variant="contained"
            onClick={handleClose}
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usersPackages: getUsersPackagesSelector(state),
    locations: getFilteredLocationsSelector(state),
    emailTemplates: getEmailTemplatesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        getUsersPackages,
        clearSubmission,
        getLocations,
        getEmailTemplates,
        clearUsersPackages,
        sendReminderEmail,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationManagement);
