import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import {toast} from 'react-toastify';
import userService from "../services/userService";
import adminService from "../services/adminService";
import {
  canManagePatients,
  roles as _roles,
  canManageUsers,
} from "../utils/constants";

const slice = createSlice({
  name: "admin",
  initialState: {
    users: [],
    currentUser: {},
    roles: [],
    currentRole: {
      role: {},
      rolePermissions: [],
    },
    permissions: [],
    currentPermission: {},
    forms: [],
    currentForm: {},
    packages: [],
    currentPackage: {},
    emailTemplates: [],
    currentEmailTemplate: {},
    usersPackages: [],
    currentSubmission: {},
    formsSubmissions: [],
    locations: [],
    currentLocation: {},
    filter: {
      username: "",
      firstName: "",
      lastName: "",
      locationId: 0,
      show: ""
    },
    userFilter: {
      username: "",
      firstName: "",
      lastName: "",
      locationId: 0,
    },
    emailTemplateAttachments: [],
  },
  reducers: {
    setUsers: (state, action) => {
      const { users } = action.payload;
      state.users = users;
    },

    setUser: (state, action) => {
      const { user, userPackages, secondaryLocations } = action.payload;
      state.currentUser = { user, userPackages, secondaryLocations };
    },

    removeUser: (state, action) => {
      const { id } = action.payload;
      state.users = state.users.filter((u) => u.id !== id);
    },

    setRoles: (state, action) => {
      const { roles } = action.payload;
      state.roles = roles;
    },

    setRole: (state, action) => {
      const { role, rolePermissions } = action.payload;
      state.currentRole = {
        role,
        rolePermissions,
      };
    },

    setRolePermissions: (state, action) => {
      const { rolePermissions } = action.payload;
      state.currentRole.rolePermissions = rolePermissions;
    },

    removeRole: (state, action) => {
      const { id } = action.payload;
      state.roles = state.roles.filter((r) => r.id !== id);
    },

    setPermissions: (state, action) => {
      const { permissions } = action.payload;
      state.permissions = permissions;
    },

    setPermission: (state, action) => {
      const { permission } = action.payload;
      state.currentPermission = permission;
    },

    removePermission: (state, action) => {
      const { id } = action.payload;
      state.permissions = state.permissions.filter((p) => p.id !== id);
    },

    setForms: (state, action) => {
      const { forms } = action.payload;
      state.forms = forms;
    },

    setForm: (state, action) => {
      const { form } = action.payload;
      state.currentForm = form;
    },

    removeForm: (state, action) => {
      const { id } = action.payload;
      state.forms = state.forms.filter((f) => f.id !== id);
    },

    setPackages: (state, action) => {
      const { packages } = action.payload;
      state.packages = packages;
    },

    setPackage: (state, action) => {
      const { _package, packageForms } = action.payload;
      state.currentPackage = {
        package: _package,
        packageForms,
      };
    },

    removePackage: (state, action) => {
      const { id } = action.payload;
      state.packages = state.packages.filter((f) => f.id !== id);
    },

    setEmailTemplates: (state, action) => {
      const { emailTemplates } = action.payload;
      state.emailTemplates = emailTemplates;
    },

    setEmailTemplate: (state, action) => {
      const { emailTemplate } = action.payload;
      state.currentEmailTemplate = emailTemplate;
    },

    removeEmailTemplate: (state, action) => {
      const { id } = action.payload;
      state.emailTemplates = state.emailTemplates.filter((et) => et.id !== id);
    },

    setEmailTemplateAttachments: (state, action) => {
      const { emailTemplateAttachments } = action.payload;
      state.emailTemplateAttachments = emailTemplateAttachments;
    },

    removeEmailTemplateAttachment: (state, action) => {
      const { id } = action.payload;
      state.emailTemplateAttachments = state.emailTemplateAttachments.filter(
        (et) => et.id !== id
      );
    },

    setUsersPackages: (state, action) => {

      const { usersPackages } = action.payload;
      state.usersPackages = [...state.usersPackages, ...usersPackages];

    },

    removeUsersPackages: (state, action) => {
      state.usersPackages = [];
    },

    setSubmission: (state, action) => {
      const { submission } = action.payload;
      state.currentSubmission = submission;
    },

    setFormsSubmissions: (state, action) => {
      const { formsSubmissions } = action.payload;
      state.formsSubmissions = formsSubmissions;
    },

    setCompletePackage: (state, action) => {
      const { patientSubmission } = action.payload;
      const { packageId, userId } = patientSubmission;

      const usersPackages = state.usersPackages.find(
        (usp) => usp.user.id === userId
      );
      const userPackage = usersPackages.userPackages.find(
        (up) => up.package.id === packageId
      );

      if (userPackage) {
        userPackage.isComplete = true;
      }
    },

    setCompleteForm: (state, action) => {
      const { patientSubmission } = action.payload;
      const { formId, packageId, userId } = patientSubmission;

      const usersPackages = state.usersPackages.find(
        (usp) => usp.user.id === userId
      );
      const userPackage = usersPackages.userPackages.find(
        (up) => up.package.id === packageId
      );

      if (userPackage) {
        var form = userPackage.forms.find((f) => f.form.id === formId);
        if (form) {
          form.isComplete = true;
        }
      }
    },

    setPackageForms: (state, action) => {
      const { packageId, userId, forms } = action.payload;

      const usersPackages = state.usersPackages.find(
        (usp) => usp.user.id === userId
      );
      const userPackage = usersPackages.userPackages.find(
        (up) => up.package.id === packageId
      );

      if (userPackage) {
        userPackage.forms = forms;
      }
    },

    setToggleLockPackage: (state, action) => {
      const { patientSubmission } = action.payload;
      const { packageId, userId } = patientSubmission;

      const usersPackages = state.usersPackages.find(
        (usp) => usp.user.id === userId
      );
      const userPackage = usersPackages.userPackages.find(
        (up) => up.package.id === packageId
      );

      if (userPackage) {
        userPackage.isLocked = !userPackage.isLocked;
      }
    },

    setToggleExportedPackage: (state, action) => {
      const { patientSubmission } = action.payload;
      const { packageId, userId } = patientSubmission;

      const usersPackages = state.usersPackages.find(
        (usp) => usp.user.id === userId
      );
      const userPackage = usersPackages.userPackages.find(
        (up) => up.package.id === packageId
      );

      if (userPackage) {
        userPackage.isExported = !userPackage.isExported;
      }
    },

    setLastEmailDate: (state, action) => {
      const { userId, lastEmailDate } = action.payload;

      const userPackages = state.usersPackages.find(
        (usp) => usp.user.id === userId
      );

      if (userPackages) {
        userPackages.user.lastEmailDate = lastEmailDate;
      }
    },

    setLocations: (state, action) => {
      const { locations } = action.payload;
      state.locations = locations;
    },

    setLocation: (state, action) => {
      const { location } = action.payload;
      state.currentLocation = location;
    },

    removeLocation: (state, action) => {
      const { id } = action.payload;
      state.locations = state.locations.filter((f) => f.id !== id);
    },

    setUserFilter: (state, action) => {
      const { filter } = action.payload;
      state.userFilter.username = filter.username;
      state.userFilter.firstName = filter.firstName;
      state.userFilter.lastName = filter.lastName;
      state.userFilter.locationId = filter.locationId;
    },
    setUserFilterItem: (state, action) => {
      const { name, value } = action.payload;
      state.userFilter[name] = value;
    },
  },
});

export const {
  setUsers,
  setUser,
  setRoles,
  setRole,
  setPermissions,
  removeUser,
  removeRole,
  setPermission,
  removePermission,
  setForms,
  setForm,
  removeForm,
  setPackages,
  setPackage,
  removePackage,
  setEmailTemplates,
  setEmailTemplate,
  removeEmailTemplate,
  setUsersPackages,
  setSubmission,
  setFormsSubmissions,
  setLocation,
  setLocations,
  removeLocation,
  setCompleteForm,
  setCompletePackage,
  setUserFilterItem,
  setUserFilter,
  setToggleLockPackage,
  setToggleExportedPackage,
  setEmailTemplateAttachments,
  removeEmailTemplateAttachment,
  setLastEmailDate,
  setPackageForms,
  removeUsersPackages
} = slice.actions;

export const reducer = slice.reducer;

//action creators
export const getUsers = () => async (dispatch) => {
  const { data: users } = await userService.getUsers();
  return dispatch({ type: setUsers.type, payload: { users } });
};

export const getUser = (id) => async (dispatch) => {
  const { data: user } = await userService.getUser(id);
  const { data: userPackages } = await userService.getUserPackages(id);
  const { data: secondaryLocations } = await userService.getUserSecondaryLocations(id);
  return dispatch({ type: setUser.type, payload: { user, userPackages, secondaryLocations } });
};

export const saveUser = (userPackage) => async (dispatch) => {
  const { data: result } = await userService.saveUser(userPackage);
  return dispatch({
    type: setUser.type,
    payload: { user: result.user, packageInfo: result.userPackages },
  });
};

export const clearUser = () => async (dispatch) => {
  return dispatch({ type: setUser.type, payload: { user: {} } });
};

export const deleteUser = (id) => async (dispatch) => {
  await userService.deleteUser(id);
  return dispatch({ type: removeUser.type, payload: { id } });
};

export const getRoles = () => async (dispatch) => {
  const { data: roles } = await adminService.getRoles();
  return dispatch({ type: setRoles.type, payload: { roles } });
};

export const getRole = (id) => async (dispatch) => {
  const { data: role } = await adminService.getRole(id);
  const { data: rolePermissions } = await adminService.getRolePermissions(
    role.roleId
  );
  return dispatch({ type: setRole.type, payload: { role, rolePermissions } });
};

export const saveRole = (rolePermission) => async (dispatch) => {
  const { data: result } = await adminService.saveRole(rolePermission);

  return dispatch({
    type: setRole.type,
    payload: {
      role: result.role,
      rolePermissions: result.permissionIds,
    },
  });
};

export const clearRole = () => async (dispatch) => {
  return dispatch({ type: setRole.type, payload: { role: {} } });
};

export const deleteRole = (id) => async (dispatch) => {
  await adminService.deleteRole(id);
  return dispatch({ type: removeRole.type, payload: { id } });
};

export const getPermissions = () => async (dispatch) => {
  const { data: permissions } = await adminService.getPermissions();
  return dispatch({ type: setPermissions.type, payload: { permissions } });
};

export const getPermission = (id) => async (dispatch) => {
  const { data: permission } = await adminService.getPermission(id);
  return dispatch({ type: setPermission.type, payload: { permission } });
};

export const savePermission = (permission) => async (dispatch) => {
  const { data: savePermission } = await adminService.savePermission(
    permission
  );
  return dispatch({
    type: setPermission.type,
    payload: { role: savePermission },
  });
};

export const clearPermission = () => async (dispatch) => {
  return dispatch({ type: setPermission.type, payload: { permission: {} } });
};

export const deletePermission = (id) => async (dispatch) => {
  await adminService.deletePermission(id);
  return dispatch({ type: removePermission.type, payload: { id } });
};

export const getForms = () => async (dispatch) => {
  const { data: forms } = await adminService.getForms();
  return dispatch({ type: setForms.type, payload: { forms } });
};

export const getForm = (id) => async (dispatch) => {
  const { data: form } = await adminService.getForm(id);
  return dispatch({ type: setForm.type, payload: { form } });
};

export const saveForm = (form) => async (dispatch) => {
  const { data: saveForm } = await adminService.saveForm(form);
  return dispatch({ type: setForm.type, payload: { form: saveForm } });
};

export const clearForm = () => async (dispatch) => {
  return dispatch({ type: setForm.type, payload: { form: {} } });
};

export const deleteForm = (id) => async (dispatch) => {
  await adminService.deleteForm(id);
  return dispatch({ type: removeForm.type, payload: { id } });
};

export const getPackages = () => async (dispatch) => {
  const { data: packages } = await adminService.getPackages();
  return dispatch({ type: setPackages.type, payload: { packages } });
};

export const getPackage = (id) => async (dispatch) => {
  const { data: _package } = await adminService.getPackage(id);
  const { data: packageForms } = await adminService.getPackageForms(id);
  return dispatch({
    type: setPackage.type,
    payload: { _package, packageForms },
  });
};

export const getUsersPackages = (filter) => async (dispatch) => {

  const { data: result } = await userService.getUsersPackagesForms(
    filter
  );

  const usersPackages = result.userPackageFormDetails;
  dispatch({ type: setUsersPackages.type, payload: { usersPackages } });
  return result;

};

export const clearUsersPackages = () => async (dispatch) => {
  return dispatch({ type: removeUsersPackages.type, payload: { } });
};

export const savePackage = (packageForm) => async (dispatch) => {
  const { data: savedPackageForm } = await adminService.savePackage(
    packageForm
  );
  return dispatch({
    type: setPackage.type,
    payload: {
      package: savedPackageForm.package,
      packageForms: savedPackageForm.formIds,
    },
  });
};

export const clearPackage = () => async (dispatch) => {
  return dispatch({ type: setPackage.type, payload: { package: {} } });
};

export const deletePackage = (id) => async (dispatch) => {
  await adminService.deletePackage(id);
  return dispatch({ type: removePackage.type, payload: { id } });
};

export const getEmailTemplates = () => async (dispatch) => {
  const { data: emailTemplates } = await adminService.getEmailTemplates();
  return dispatch({
    type: setEmailTemplates.type,
    payload: { emailTemplates },
  });
};

export const getEmailTemplate = (id) => async (dispatch) => {
  const { data: emailTemplate } = await adminService.getEmailTemplate(id);
  return dispatch({ type: setEmailTemplate.type, payload: { emailTemplate } });
};

export const saveEmailTemplate = (emailTemplate) => async (dispatch) => {
  const { data: savedEmailTemplate } = await adminService.saveEmailTemplate(
    emailTemplate
  );
  return dispatch({
    type: setEmailTemplate.type,
    payload: { emailTemplate: savedEmailTemplate },
  });
};

export const getEmailTemplateAttachments = (emailTemplateId) => async (
  dispatch
) => {
  const {
    data: emailTemplateAttachments,
  } = await adminService.getEmailTemplateAttachments(emailTemplateId);
  return dispatch({
    type: setEmailTemplateAttachments.type,
    payload: { emailTemplateAttachments },
  });
};

export const saveEmailTemplateAttachments = (emailTemplateAttachment) => async (
  dispatch
) => {
  const {
    data: savedEmailTemplateAttachments,
  } = await adminService.saveEmailTemplateAttachments(emailTemplateAttachment);
  return dispatch({
    type: setEmailTemplateAttachments.type,
    payload: { emailTemplateAttachments: savedEmailTemplateAttachments },
  });
};

export const deleteEmailTemplateAttachment = (id) => async (dispatch) => {
  await adminService.deleteEmailTemplateAttachment(id);
  return dispatch({
    type: removeEmailTemplateAttachment.type,
    payload: { id },
  });
};

export const clearEmailTemplate = () => async (dispatch) => {
  return dispatch({
    type: setEmailTemplate.type,
    payload: { emailTemplate: {} },
  });
};

export const deleteEmailTemplate = (id) => async (dispatch) => {
  await adminService.deleteEmailTemplate(id);
  return dispatch({ type: removeEmailTemplate.type, payload: { id } });
};

export const saveSubmission = (patientSubmission) => async (dispatch) => {
  const { data: result } = await userService.saveSubmission(patientSubmission);

  //update store
  dispatch({
    type: setCompleteForm.type,
    payload: { patientSubmission: result.patientSubmission },
  });

  if (result.isPackageComplete) {
    dispatch({
      type: setCompletePackage.type,
      payload: { patientSubmission: result.patientSubmission },
    });
  }
};

export const getSubmission = (patientSubmission) => async (dispatch) => {
  const { data: submission } = await userService.getSubmission(
    patientSubmission
  );
  return dispatch({ type: setSubmission.type, payload: { submission } });
};

export const getFormsSubmissions = (patientSubmission) => async (dispatch) => {
  const { data: formsSubmissions } = await userService.getFormsSubmissions(
    patientSubmission
  );
  return dispatch({
    type: setFormsSubmissions.type,
    payload: { formsSubmissions },
  });
};

export const clearSubmission = () => async (dispatch) => {
  return dispatch({ type: setSubmission.type, payload: { submission: {} } });
};

export const getLocations = () => async (dispatch) => {
  const { data: locations } = await adminService.getLocations();
  return dispatch({ type: setLocations.type, payload: { locations } });
};

export const getLocation = (id) => async (dispatch) => {
  const { data: location } = await adminService.getLocation(id);
  return dispatch({ type: setLocation.type, payload: { location } });
};

export const saveLocation = (location) => async (dispatch) => {
  const { data: saveLocation } = await adminService.saveLocation(location);
  return dispatch({
    type: setLocation.type,
    payload: { location: saveLocation },
  });
};

export const clearLocation = () => async (dispatch) => {
  return dispatch({ type: setLocation.type, payload: { location: {} } });
};

export const deleteLocation = (id) => async (dispatch) => {
  await adminService.deleteLocation(id);
  return dispatch({ type: removeLocation.type, payload: { id } });
};

export const updateUserFilterItem = (name, value) => async (dispatch) => {
  return dispatch({ type: setUserFilterItem.type, payload: { name, value } });
};

export const clearUserFilter = (locationId) => async (dispatch) => {
  return dispatch({
    type: setUserFilter.type,
    payload: {
      filter: {
        username: "",
        firstName: "",
        lastName: "",
        show: "",
        locationId,
      },
    },
  });
};

export const togglePackageLock = (patientSubmission) => async (dispatch) => {
  await userService.togglePatientPackageLock(patientSubmission);
  dispatch({ type: setToggleLockPackage.type, payload: { patientSubmission } });
};

export const togglePackageExported = (patientSubmission) => async (
  dispatch
) => {
  await userService.togglePatientPackageExported(patientSubmission);
  dispatch({
    type: setToggleExportedPackage.type,
    payload: { patientSubmission },
  });
};

export const sendReminderEmail = (userPackage) => async (dispatch) => {
  const { data: lastEmailDate } = await userService.sendReminder(userPackage);
  dispatch({
    type: setLastEmailDate.type,
    payload: { userId: userPackage.user.id, lastEmailDate },
  });
};

export const loadPackageForms = (userPackage) => async (dispatch) => {
  const { data: forms } = await userService.getPackageForms(userPackage);
  dispatch({
    type: setPackageForms.type,
    payload: {
      packageId: userPackage.packageId,
      userId: userPackage.userId,
      forms,
    },
  });
};

export const clearPackageForms = (userPackage) => async (dispatch) => {
  dispatch({
    type: setPackageForms.type,
    payload: {
      packageId: userPackage.packageId,
      userId: userPackage.userId,
      forms: [],
    },
  });
};

//administrator functions
export const deleteExportedPatients = () => async(dispatch) => {

  try{
    const {data: numberOfDeletedUsers} = await adminService.deleteExportedPatients();
    toast.success(`Succesfully deleted ${numberOfDeletedUsers} exported patients!`);

  }
  catch(e){
    toast.error(`Settings failed to update ${e.message}!`);
  }
}

export const getUserSelector = createSelector(
  (state) => state.admin.currentUser.user,
  (user) => {
    return user;
  }
);

export const getRolesSelector = createSelector(
  (state) => state.admin.roles,
  (state) => state.currentUser.rolePermissions,
  (roles, rolePermissions) => {
    if (
      canManagePatients(rolePermissions) &&
      !canManageUsers(rolePermissions)
    ) {
      roles = roles.filter((r) => r.roleId === _roles.patient);
    }

    return roles;
  }
);

export const getRoleSelector = createSelector(
  (state) => state.admin.currentRole.role,
  (role) => {
    return role;
  }
);

export const getRolePermissionsSelector = createSelector(
  (state) => state.admin.currentRole.rolePermissions,
  (rolePermissions) => {
    return rolePermissions;
  }
);

export const getPermissionsSelector = createSelector(
  (state) => state.admin.permissions,
  (permissions) => {
    return permissions;
  }
);

export const getPermissionSelector = createSelector(
  (state) => state.admin.currentPermission,
  (currentPermission) => {
    return currentPermission;
  }
);

export const getFormsSelector = createSelector(
  (state) => state.admin.forms,
  (forms) => {
    return forms;
  }
);

export const getFormSelector = createSelector(
  (state) => state.admin.currentForm,
  (currentForm) => {
    return currentForm;
  }
);

export const getPackagesSelector = createSelector(
  (state) => state.admin.packages,
  (packages) => {
    return packages;
  }
);

export const getConsentPackageIdsSelector = createSelector(
  (state) => state.admin.packages,
  (packages) => {
    return packages.filter((p) => p.isConsentPackage === true).map((p) => p.id);
  }
);

export const getPackageSelector = createSelector(
  (state) => state.admin.currentPackage.package,
  (_package) => {
    return _package;
  }
);

export const getPackageFormsSelector = createSelector(
  (state) => state.admin.currentPackage.packageForms,
  (packageForms) => {
    return packageForms;
  }
);

export const getEmailTemplatesSelector = createSelector(
  (state) => state.admin.emailTemplates,
  (emailTemplates) => {
    return emailTemplates;
  }
);

export const getEmailTemplateSelector = createSelector(
  (state) => state.admin.currentEmailTemplate,
  (currentEmailTemplate) => {
    return currentEmailTemplate;
  }
);

export const getUserPackagesSelector = createSelector(
  (state) => state.admin.currentUser.userPackages,
  (userPackages) => {
    return userPackages;
  }
);

export const getUserSecondaryLocations = createSelector(
  (state) => state.admin.currentUser.secondaryLocations,
  (secondaryLocations) => {
    return secondaryLocations;
  }
);

export const getSubmissionSelector = createSelector(
  (state) => state.admin.currentSubmission,
  (currentSubmission) => {
    return currentSubmission;
  }
);

export const getFormsSubmissionsSelector = createSelector(
  (state) => state.admin.formsSubmissions,
  (formsSubmissions) => {
    return formsSubmissions;
  }
);

export const getLocationsSelector = createSelector(
  (state) => state.admin.locations,
  (state) => state.currentUser.rolePermissions,
  (state) => state.currentUser.user.locationId,
  (locations) => locations
  
);

export const getFilteredLocationsSelector = createSelector(
  (state) => state.admin.locations,
  (state) => state.currentUser.secondaryLocations,
  (state) => state.currentUser.user.locationId,

  (locations, secondaryLocations, locationId) => {

    return locations.filter(l => l.id == parseInt(locationId) || secondaryLocations.find(id => id == l.id));
  }
);

export const getLocationSelector = createSelector(
  (state) => state.admin.currentLocation,
  (currentLocation) => {
    return currentLocation;
  }
);

export const getFilterSelector = createSelector(
  (state) => state.admin.filter,
  (filter) => {
    return filter;
  }
);

export const getUserFilterSelector = createSelector(
  (state) => state.admin.userFilter,
  (userFilter) => {
    return userFilter;
  }
);

export const getUserPackagesByUserSelector = (userId) =>
  createSelector(
    (state) => state.admin.usersPackages,
    (usersPackages) => {
      const userPackages = usersPackages.find((usp) => usp.user.id === userId);
      return userPackages;
    }
  );

export const getUsersPackagesSelector = createSelector(
  (state) => state.admin.usersPackages,
  (state) => state.admin.filter,
  (usersPackages) => {
    return usersPackages;
  }
);

export const getUsersSelector = createSelector(
  (state) => state.admin.users,
  (state) => state.admin.userFilter,
  (state) => state.currentUser.rolePermissions,
  (users, userFilter, rolePermissions) => {
    const { username, firstName, lastName, locationId } = userFilter;

    if (
      canManagePatients(rolePermissions) &&
      !canManageUsers(rolePermissions)
    ) {
      users = users.filter((u) => u.roleId === _roles.patient);
    }

    if (username) {
      users = users.filter(
        (u) =>
          u.username &&
          u.username.toLowerCase().includes(username.toLowerCase())
      );
    }

    if (firstName) {
      users = users.filter(
        (u) =>
          u.firstName &&
          u.firstName.toLowerCase().includes(firstName.toLowerCase())
      );
    }

    if (lastName) {
      users = users.filter(
        (u) =>
          u.lastName &&
          u.lastName.toLowerCase().includes(lastName.toLowerCase())
      );
    }

    if (locationId) {
      users = users.filter((u) => u.locationId && u.locationId === locationId);
    }

    return users;
  }
);

export const getEmailTemplateAttachmentsSelector = createSelector(
  (state) => state.admin.emailTemplateAttachments,
  (emailTemplateAttachments) => {
    return emailTemplateAttachments;
  }
);
