import React, { Fragment, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { resetPassword } from "../../store/user";
import { Button, Grid, TextField, makeStyles, Typography } from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import { resetPasswordSchema } from "./reset-password-schema";
import logo from "../../Images/medsleep-logo.jpg";
import { history } from "../../utils/history";
import auth from "../../services/authService";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  centeredForm: theme.centeredForm,
  logo: theme.logo,
  button: theme.button,
  message: {
    marginTop: "1rem",
    marginBottom: "1rem"
  }
}));

const ResetPassword = ({ ...props }) => {
  const classes = useStyles();

  useEffect(() => {
    const init = () => {};
    init();
  }, []);

  const onSubmit = async (values) => {

    const {resetPassword} = props;
    const token = auth.getCurrentUser();
    
    try{
      await resetPassword(token.primarysid,values.newPassword);
      toast.success("Password updated successfully!");
      history.push("/patient-dashboard");
    }
    catch(ex){
      toast.error("Failed to update password!");
    }

  };

  return (
    <Fragment>
      <div className="centered-container">
        <Grid
          container
          alignContent="center"
          justify="center"
          margin="normal"
          spacing={4}
        >
          <Formik
            initialValues={{ newPassword: "", confirmNewPassword: "" }}
            validationSchema={resetPasswordSchema}
            onSubmit={(values) => {
              onSubmit(values);
            }}
            render={({
              values,
              setFieldValue,
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
              touched,
              ...formProps
            }) => (
              <form onSubmit={handleSubmit} className={classes.centeredForm}>
                <Grid item xs={12}>
                  <img src={logo} className={classes.logo} />
                </Grid>
                <Grid item xs={12} className={classes.message}>
                  <Typography>Your account was setup with a temporary password and/or was updated by administrator staff. Please enter a new password to continue.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errors.newPassword && touched.newPassword ? true : false}
                    name="newPassword"
                    type="password"
                    label="New Password"
                    margin="normal"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                  />
                  <ErrorMessage name="newPassword">
                    {(msg) => <div className="error error-message" dangerouslySetInnerHTML={{__html: msg}} />}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errors.confirmNewPassword && touched.confirmNewPassword ? true : false}
                    name="confirmNewPassword"
                    type="password"
                    label="Confirm New Password"
                    margin="normal"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmNewPassword}
                  />
                  <ErrorMessage name="confirmNewPassword">
                    {(msg) => <div className="error error-message">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} align="right">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Continue
                  </Button>
                </Grid>
              </form>
            )}
          />
        </Grid>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ resetPassword }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
