import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "../../../../utils/history";
import {
  Grid,
  makeStyles,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { permissionSchema } from "./permission-schema";
import { toast } from "react-toastify";
import {
  getPermission,
  getPermissions,
  getPermissionSelector,
  getPermissionsSelector,
  savePermission,
} from "../../../../store/admin";

const useStyles = makeStyles((theme) => ({
  backArrowOutter: theme.backArrowOutter,
  darkArrow: theme.darkArrow,
  form: theme.form,
  select: theme.select,
}));

const Permission = ({ ...props }) => {
  const classes = useStyles();

  const {
    match: { params },
    permission = {},
  } = props;

  useEffect(() => {
    const init = async () => {
      const { getPermission, getPermissions } = props;
      const {
        match: { params },
      } = props;

      await getPermissions();

      if (params.id !== "new") await getPermission(params.id);
    };
    init();
  }, []);

  const handleSave = async (values) => {
    const {
      match: { params },
      savePermission,
      permissions,
    } = props;

    const permission = {
      id: params.id === "new" ? 0 : params.id,
      permissionId: values.permissionId,
      name: values.name,
    };

    const exists = permissions.find(
      (r) => r.permissionId === parseInt(permission.permissionId)
    );

    if (exists && exists.id !== parseInt(params.id)) {
      toast.error(
        `Permission already exists with permission id ${permission.permissionId}`
      );
      return;
    } else {
      await savePermission(permission);
      history.push("/permissions");
    }
  };

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.backArrowOutter}>
          <span
            onClick={(e) => history.push("/permissions")}
            className={classes.darkArrow}
          >
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Back to
            Permissions
          </span>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            {params.id === "new"
              ? "New Permission"
              : `Edit Permission ${params.id} `}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              permissionId: permission.permissionId
                ? permission.permissionId
                : "",
              name: permission.name ? permission.name : "",
            }}
            validationSchema={permissionSchema}
            onSubmit={(values) => {
              handleSave(values);
            }}
            render={({
              values,
              setFieldValue,
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
              touched,
              ...formProps
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      error={
                        errors.permissionId && touched.permissionId
                          ? true
                          : false
                      }
                      name="permissionId"
                      type="text"
                      label="Permission Id"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.permissionId}
                    />
                    <ErrorMessage name="permissionId">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      error={errors.name && touched.name ? true : false}
                      name="name"
                      type="name"
                      label="Name"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <ErrorMessage name="name">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} align="right">
                    <Button type="submit" variant="contained" color="primary">
                      Save Permission
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    permissions: getPermissionsSelector(state),
    permission: getPermissionSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      { getPermissions, getPermission, savePermission },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Permission);
