import React, { Fragment } from "react";
import { Grid } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getUser, getUserSelector, saveSubmission } from "../../../store/admin";
import ViewFormButton from "../../shared/view-form-button";
import ExportButton from "../../shared/export-button";
import CompleteButton from "../../shared/complete-button";

const MenuBar = ({ ...props }) => {
  const { formDetail, usersPackage, userPackage } = props;

  const onFormClick = async (e, id, packageId, userId) => {
    e.preventDefault();
    window.open(`/application-form/${userId}/${packageId}/${id}`, "Form View");
  };

  const onExport = (e, id, packageId, userId) => {
    e.preventDefault();
    window.open(
      `/export-form/${userId}/${packageId}/${id}/false`,
      "Export Form",
      "width=500,height=500"
    );
  };

  const onComplete = async (e, id, packageId, userId) => {
    e.preventDefault();

    const { saveSubmission } = props;
    var patientSubmission = {
      userId,
      packageId,
      formId: parseInt(id),
      submissionId: "",
      submission: "",
    };
    await saveSubmission(patientSubmission);
    await getUser(userId);
  };

  return (
    <Fragment>
      <Grid item xs={4}>
        <ViewFormButton
          handleFormClick={onFormClick}
          formId={formDetail.form.id}
          userId={usersPackage.user.id}
          packageId={userPackage.package.id}
          isComplete={formDetail.isComplete}
        ></ViewFormButton>
      </Grid>
      <Grid item xs={4}>
        <ExportButton
          handleExport={onExport}
          formId={formDetail.form.id}
          userId={usersPackage.user.id}
          packageId={userPackage.package.id}
        ></ExportButton>
      </Grid>
      {!formDetail.isComplete && (
        <Grid item xs={4}>
          <CompleteButton
            handleComplete={onComplete}
            formId={formDetail.form.id}
            userId={usersPackage.user.id}
            packageId={userPackage.package.id}
          ></CompleteButton>
        </Grid>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: getUserSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        getUser,
        saveSubmission,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
