import React from 'react';
import { makeStyles, Button, MobileStepper, useTheme} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles(theme => ({
    stepperContainer: {
        flexGrow: 1,
        maxWidth:"100%"
    }
}));

const FormStepper = ({ ...props }) => {

    const classes = useStyles();      
    const theme = useTheme();
    const { maxSteps, activeStep, handleNext, handleBack } = props;

    return(
        <div className={classes.stepperContainer}>
            <MobileStepper
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        Next
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Back
                    </Button>
                }
            />                                            
        </div>
    );    
}

export default FormStepper