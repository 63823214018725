import React from 'react';
import {Formik, ErrorMessage} from 'formik';
import { Button, Grid, Typography, TextField, Link } from '@material-ui/core';
import { sendGridSettingsSchema } from './send-grid-settings-schema';

const SendGridSettings = ({ ...props }) => {

    const { configuration, handleSaveSendGridSettings} = props;
    const { sendGridAPIKey, sendGridFromEmail, sendGridAPIEndpoint } = configuration;
    return(
        <Formik
            enableReinitialize={true}
            initialValues={{ 
                apiKey: sendGridAPIKey ? sendGridAPIKey : "",
                fromEmail: sendGridFromEmail ? sendGridFromEmail : "",
                apiEndpoint: sendGridAPIEndpoint ? sendGridAPIEndpoint : ""
            }}
            validationSchema={sendGridSettingsSchema}

            onSubmit={(values) => {
                handleSaveSendGridSettings(values);
            }}

            render={({ values, setFieldValue, handleChange, handleSubmit, handleBlur, errors, touched, ...formProps }) => (
                <form onSubmit={handleSubmit}>
                    
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            SendGrid Settings
                        </Typography>             
                    </Grid>
                    <Grid item xs={12}>    
                        <Link
                            component="button"
                            variant="body2"
                            onClick={(e) => {
                                e.preventDefault();
                                window.open('https://app.sendgrid.com/', '_blank');
                            }}
                            >
                            Sendgrid Dashboard
                        </Link>            
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={(errors.apiKey && touched.apiKey) ? true : false}
                            name="apiKey"
                            type="text"
                            label="API Key"
                            type="password"
                            margin="normal"
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.apiKey}
                        />
                        <ErrorMessage name="apiKey">{msg => <div className="error error-message">{msg}</div>}</ErrorMessage>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={(errors.apiEndpoint && touched.apiEndpoint) ? true : false}
                            name="apiEndpoint"
                            type="text"
                            label="API Endpoint"
                            margin="normal"
                            type="password"
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.apiEndpoint}
                        />
                        <ErrorMessage name="apiEndpoint">{msg => <div className="error error-message">{msg}</div>}</ErrorMessage>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={(errors.fromEmail && touched.fromEmail) ? true : false}
                            name="fromEmail"
                            type="text"
                            label="From Email"
                            margin="normal"
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.fromEmail}
                        />
                        <ErrorMessage name="fromEmail">{msg => <div className="error error-message">{msg}</div>}</ErrorMessage>
                    </Grid>
                    <Grid item xs={3} align="right">
                        <Button type="submit" variant="contained" color="primary" fullWidth>Save</Button>
                    </Grid>
                
                </form>
            )}
        />
    );    
}

export default SendGridSettings