import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { history } from '../../../utils/history';
import { 
    makeStyles, 
    Grid, 
    Typography, 
    Table, 
    TableBody, 
    TableRow, 
    TableCell, 
    TablePagination, 
    TableContainer, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button } from '@material-ui/core';

import { getRolesSelector, getRoles, clearRole,deleteRole} from '../../../store/admin';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { TablePaginationActions } from '../../shared/pagination';
import { EnhancedTableHead, stableSort, getSorting } from '../../shared/tableHeader';
import EditButton from '../../shared/edit-button';
import DeleteButton from '../../shared/delete-button/delete-button-view';

const useStyles = makeStyles(theme => ({
    backArrowOutter: theme.backArrowOutter,
    darkArrow: theme.darkArrow,
    button: theme.button
}));


const Roles = ({ ...props }) => {

    const classes = useStyles();      
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [open, setOpen] = React.useState(false);
    const [selectedRoleId, setSelectedRoleId] = React.useState(0);

    useEffect(() => {
        const init = async () => {
            const { getRoles, clearRole } = props;
            clearRole();
            await getRoles();
        }
        init();
        
    }, []);

    const handleClose = () => {
        setOpen(false);
        setSelectedRoleId(0);
    };

    const onDelete = (id) => {
        setSelectedRoleId(id);
        setOpen(true);
    }

    const handleConfirmDelete = async () => {

        const {deleteRole,clearRole} = props;
        await deleteRole(selectedRoleId)
        clearRole();
        setOpen(false);
    }

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = e => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };    

    const handleRequestSort = (e, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };
    
    const onEdit = (id) => {
        history.push(`/role/${id}`);
    }

    const headCells = [
        { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
        { id: 'roleId', numeric: false, disablePadding: false, label: 'Role Id' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'edit', numeric: false, disablePadding: false, label: '' },
        { id: 'delete', numeric: false, disablePadding: false, label: '' },
    ];
    
    return(
        <Fragment>

            <Grid container spacing={1}>
                <Grid item xs={12} className={classes.backArrowOutter}>
                    <span onClick={e => history.push("/user-management")} className={classes.darkArrow}>
                        <FontAwesomeIcon icon={faArrowLeft}>
                            </FontAwesomeIcon> Back to User Management </span>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Typography variant="h3" gutterBottom>
                        Roles
                    </Typography>
                </Grid>      
                <Grid item xs={12} sm={2}>
                    <Button  variant="contained" type="button" color="primary" className={classes.button} onClick={e => history.push(`/role/new`)}>New Role</Button>
                </Grid>
            </Grid>

            { props.roles &&
                <Grid item xs={12} style={{overflow: "auto"}}>
                    
                    <TableContainer>
                        <Table 
                            className={classes.table}
                            size="small"
                            aria-labelledby="roles"
                            aria-label="enhanced table">
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={props.roles.length}
                                    headCells={headCells}
                                    />
                                <TableBody>
                                    {stableSort(props.roles, getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(role => {
                                            return (
                                                <TableRow key={role.id}  className={classes.tableRow}>
                                                    <TableCell component="th" scope="row" >{role.id}</TableCell>
                                                    <TableCell component="th" scope="row" >{role.roleId}</TableCell>
                                                    <TableCell component="th" scope="row" >{role.name}</TableCell>
                                                    <TableCell component="th" scope="row" ><EditButton pid={role.id} handleEdit={onEdit} /></TableCell>
                                                    <TableCell component="th" scope="row" ><DeleteButton pid={role.id} handleDelete={onDelete} /></TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                        </Table>                    
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25,100]}
                        component="div"
                        count={props.roles.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Grid>   
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete Role"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete selected role?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant="contained" onClick={handleConfirmDelete} color="primary">
                    Yes
                </Button>
                <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
                    No
                </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );    
}

const mapStateToProps = state => {

    return {
        roles: getRolesSelector(state)
    };

};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({getRoles, clearRole, deleteRole}, dispatch)   
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Roles)