import React, { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { history } from "../../../utils/history";
import { Form } from "react-formio";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { saveSubmission } from "../../../store/user";
import PrintButton from "../../shared/print-button";
import { getFormSelector, getForm, getSubmission } from "../../../store/admin";
import { getConfiguration, getSettings } from "../../../store/setting";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  button: theme.button,
  paper: theme.paper,
  backArrowOutter: theme.backArrowOutter,
  darkArrow: theme.darkArrow,
}));

const ApplicationForm = ({ ...props }) => {
  const classes = useStyles();
  const {
    match: { params },
    getForm,
    getSettings,
    getSubmission,
  } = props;
  const [formURL, setFormURL] = useState("");
  const [formSubmission, setFormSubmission] = useState({});
  const [showPrint, setShowPrint] = useState(false);
  const [isManualSubmission, setIsManualSubmission] = useState(false);

  useEffect(() => {
    const init = async () => {
      const { userId, packageId, id } = params;
      const {
        payload: { configuration },
      } = await getSettings();
      const {
        payload: { form },
      } = await getForm(id);

      const { formioLiveEndpoint } = configuration;

      setFormURL(`${formioLiveEndpoint}/${form.apiPath}`);

      var patientSubmission = {
        userId,
        packageId,
        formId: id,
      };

      const {
        payload: { submission },
      } = await getSubmission(patientSubmission);

      if (submission.submission) {
        var data = {
          data: JSON.parse(submission.submission),
        };

        setFormSubmission(data);
      } else {
        setShowPrint(false);
        setIsManualSubmission(true);
      }
    };
    init();
  }, []);

  const onPrint = () => {
    const { userId, packageId, id } = params;
    window.open(
      `/export-form/${userId}/${packageId}/${id}/true`,
      "Export Form",
      "width=500,height=500"
    );
  };

  return (
    <Fragment>
      <Helmet>
        <title>{props.form.name}</title>
      </Helmet>
      <Grid item xs={12} className={classes.backArrowOutter}>
        <span
          onClick={(e) => history.push("/application-management")}
          className={classes.darkArrow}
        >
          <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Back to
          Application Management
        </span>
      </Grid>
      {isManualSubmission && <h2>Form was manually submitted.</h2>}
      {!isManualSubmission && (
        <Fragment>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={10}></Grid>
            {showPrint && (
              <Grid item xs={12} sm={2}>
                <PrintButton handlePrint={onPrint} />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Form
              src={formURL}
              submission={formSubmission}
              options={{ readOnly: true }}
              onRender={function () {
                setShowPrint(true);
              }}
            />
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    form: getFormSelector(state),
    configuration: getConfiguration(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      { getForm, getSettings, saveSubmission, getSubmission },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm);
