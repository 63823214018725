import React from 'react'
import { 
    Tooltip,IconButton
    } from '@material-ui/core';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const CompleteButton = ({...props}) => {

    const { handleComplete, formId, packageId, userId } = props;

    return (
        <Tooltip title="Complete" onClick={e => handleComplete(e, formId, packageId, userId)}>
            <IconButton aria-label="complete">
                <CheckCircleOutlineIcon />
            </IconButton>
        </Tooltip>     
    )
}

export default CompleteButton;