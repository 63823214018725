import http from "./httpService";
import auth from "./authService";

const apiEndPoint = `${window.location.origin}/api/user`;

http.setJwt(auth.getJwt());

function userUrl(id) {
  return `${apiEndPoint}/${id}`;
}

export function deleteUser(userId) {
  return http.delete(userUrl(userId));
}

export function getUser(userId) {
  return http.get(userUrl(userId));
}

export function getRolePermissions(roleId) {
  return http.get(`${apiEndPoint}/${roleId}/permissions`);
}

export function getUserPackages(userId) {
  return http.get(`${apiEndPoint}/${userId}/packages`);
}

export function getUserPackagesForms(userId) {
  return http.get(`${apiEndPoint}/${userId}/packages/forms`);
}

export function getUsersPackagesForms(filter) {
  return http.post(`${apiEndPoint}/packages/forms/filter`, filter);
}

export function getPackageForms(userPackage) {
  return http.post(`${apiEndPoint}/packages/forms`, userPackage);
}

export function saveUser(userPackage) {
  if (userPackage.user.id) {
    const body = { ...userPackage };
    return http.put(apiEndPoint + "/edit", body);
  }
  return http.post(apiEndPoint + "/new", userPackage);
}

export function getUsers() {
  return http.get(apiEndPoint + "/all");
}

export function saveSubmission(patientSubmission) {
  return http.post(apiEndPoint + "/submission", patientSubmission);
}

export function getSubmission(patientSubmission) {
  return http.post(apiEndPoint + "/form/submission", patientSubmission);
}

export function getFormsSubmissions(patientSubmission) {
  return http.post(apiEndPoint + "/forms/submissions", patientSubmission);
}

export function sendReminder(userPackage) {
  return http.post(apiEndPoint + "/reminder", userPackage);
}

export function togglePatientPackageLock(patientSubmission) {
  return http.post(apiEndPoint + "/package/lock/toggle", patientSubmission);
}

export function togglePatientPackageExported(patientSubmission) {
  return http.post(apiEndPoint + "/package/exported/toggle", patientSubmission);
}

export async function resetPassword(userId, password) {
  return http.post(apiEndPoint + "/reset-password", { id: userId, password });
}

export function getUserSecondaryLocations(id) {
  return http.get(`${apiEndPoint}/user/${id}/locations/secondary`);
}

export default {
  getUser,
  deleteUser,
  saveUser,
  getUsers,
  getRolePermissions,
  getUserPackages,
  getUserPackagesForms,
  saveSubmission,
  getUsersPackagesForms,
  getSubmission,
  sendReminder,
  getFormsSubmissions,
  togglePatientPackageLock,
  togglePatientPackageExported,
  getPackageForms,
  resetPassword,
  getUserSecondaryLocations
  
};
