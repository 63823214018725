import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { history } from '../../utils/history';
import { 
    makeStyles, 
    Grid, 
    Typography, 
    Table, 
    TableBody, 
    TableRow, 
    TableCell, 
    TablePagination, 
    TableContainer, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Link,
    Button } from '@material-ui/core';

import { getEmailTemplatesSelector, getEmailTemplates, clearEmailTemplate, deleteEmailTemplate } from '../../store/admin';
import { TablePaginationActions } from '../shared/pagination';
import { EnhancedTableHead, stableSort, getSorting } from '../shared/tableHeader';
import EditButton from '../shared/edit-button';
import DeleteButton from '../shared/delete-button/delete-button-view';

const useStyles = makeStyles(theme => ({
    button: theme.button
}));


const EmailTemplateManagement = ({ ...props }) => {

    const classes = useStyles();      
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [open, setOpen] = React.useState(false);
    const [selectedEmailTemplateId, setSelectedEmailTemplateId] = React.useState(0);

    
    useEffect(() => {
        const init = async () => {
            const { getEmailTemplates, clearEmailTemplate } = props;
            await getEmailTemplates();
            clearEmailTemplate();
        }
        init();
        
    }, []);

    const handleClose = () => {
        setOpen(false);
        setSelectedEmailTemplateId(0);
    };

    const onDelete = (id) => {
        setSelectedEmailTemplateId(id);
        setOpen(true);
    }

    const handleConfirmDelete = async () => {

        const {deleteEmailTemplate,clearEmailTemplate} = props;
        await deleteEmailTemplate(selectedEmailTemplateId)
        clearEmailTemplate();
        setOpen(false);
    }

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = e => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };    

    const handleRequestSort = (e, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };
    
    const onEdit = (id) => {
        history.push(`/email-template/${id}`);
    }

    const headCells = [
        { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'templateId', numeric: false, disablePadding: false, label: 'Template Id' },
        { id: 'edit', numeric: false, disablePadding: false, label: '' },
        { id: 'delete', numeric: false, disablePadding: false, label: '' },
    ];
    
    return(
        <Fragment>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={9}>
                    <Typography variant="h3" gutterBottom>
                        Email Template Management
                    </Typography>
                </Grid>      
                <Grid item xs={12} sm={3}>
                    <Button  variant="contained" type="button" color="secondary" className={classes.button} onClick={e => history.push(`/email-template/new`)}>New Email Template</Button>
                </Grid>
                <Grid item xs={12}>    
                    <Link
                        component="button"
                        variant="body2"
                        onClick={(e) => {
                            e.preventDefault();
                            window.open('https://app.sendgrid.com/', '_blank');
                        }}
                        >
                        Create and manage templates at 'Sendgrid Dashboard'
                    </Link>            
                </Grid>
            </Grid>

            { props.emailTemplates &&
                <Grid item xs={12} style={{overflow: "auto"}}>
                    
                    <TableContainer>
                        <Table 
                            className={classes.table}
                            size="small"
                            aria-labelledby="forms"
                            aria-label="enhanced table">
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={props.emailTemplates.length}
                                    headCells={headCells}
                                    />
                                <TableBody>
                                    {stableSort(props.emailTemplates, getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(emailTemplate => {
                                            return (
                                                <TableRow key={emailTemplate.id}  className={classes.tableRow}>
                                                    <TableCell component="th" scope="row" >{emailTemplate.id}</TableCell>
                                                    <TableCell component="th" scope="row" >{emailTemplate.name}</TableCell>
                                                    <TableCell component="th" scope="row" >{emailTemplate.templateId}</TableCell>
                                                    <TableCell component="th" scope="row" ><EditButton pid={emailTemplate.id} handleEdit={onEdit} /></TableCell>
                                                    <TableCell component="th" scope="row" ><DeleteButton pid={emailTemplate.id} handleDelete={onDelete} /></TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                        </Table>                    
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25,100]}
                        component="div"
                        count={props.emailTemplates.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Grid>   
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete Email Template"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete selected email template?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant="contained" onClick={handleConfirmDelete} color="primary">
                    Yes
                </Button>
                <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
                    No
                </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );    
}

const mapStateToProps = state => {

    return {
        emailTemplates: getEmailTemplatesSelector(state)
    };

};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ getEmailTemplates, clearEmailTemplate, deleteEmailTemplate }, dispatch)   
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailTemplateManagement)