import React, { Fragment } from "react";
import PrintButton from "../shared/print-button";
import { Form } from "react-formio";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { Element } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  hover: theme.hover,
}));

const FormView = ({ ...props }) => {
  const classes = useStyles();
  const {
    userPackage,
    handleSingleSelect,
    handlePrint,
    formURL,
    formSubmission,
    handleSubmitDone,
  } = props;

  return (
    <Fragment>
      {userPackage.forms &&
        userPackage.forms.map((formDetails, index) => (
          <Grid
            item
            xs={12}
            sm={2}
            key={formDetails.form.id}
            style={{
              textDecoration: formDetails.isComplete ? "line-through" : "none",
            }}
            className={classes.hover}
            onClick={(e) => handleSingleSelect(index)}
          >
            <Typography>{formDetails.form.name}</Typography>
          </Grid>
        ))}
      <Grid item xs={12} sm={2}>
        <PrintButton handlePrint={handlePrint} />
      </Grid>
      <Grid item xs={12}>
        <Element name="form">
          <Form
            name="form"
            src={formURL}
            submission={formSubmission}
            onRender={function () {
              //hide patient-hide elements
              window.document
                .getElementsByClassName("patient-hide")
                .forEach((e) => e.setAttribute("style", "display:none"));
            }}
            onSubmitDone={(result) => handleSubmitDone(result)}
          />
        </Element>
      </Grid>
    </Fragment>
  );
};

export default FormView;
