import React, { Fragment, useState } from 'react';
import { 
    Button, 
    Grid, 
    Typography,    
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions } 
from '@material-ui/core';


const AdminFunctionsSettings = ({ ...props }) => {

    const [open, setOpen] = useState(false);

    const { classes, handleDeleteExportedPatientData } = props;

    const onDeleteExportedPatientData = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <Fragment>
            <Grid item xs={12} sm={6}>
                <Typography variant="h4" gutterBottom>
                    Administrator Functions
                </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={onDeleteExportedPatientData}
                >
                    Delete Exported Patient Data
                </Button>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Exported Patient Data Delete"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete exported patient data?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button
                    variant="contained"
                    onClick={e => handleDeleteExportedPatientData(e,setOpen)}
                    color="primary"
                >
                    Yes
                </Button>
                <Button
                    variant="contained"
                    onClick={handleClose}
                    color="primary"
                    autoFocus
                >
                    No
                </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );    
}

export default AdminFunctionsSettings