import React from 'react'
import { 
    Tooltip,IconButton
    } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';


const DeleteButton = ({...props}) => {

    const { handleDelete, pid } = props;

    return (
        <Tooltip title="Delete" onClick={e => handleDelete(pid)}>
            <IconButton aria-label="delete">
                <DeleteIcon />
            </IconButton>
        </Tooltip>     
    )
}

export default DeleteButton;