import React, { Fragment } from "react";
import { Grid, Typography } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ExportAllButton from "../../shared/export-all-button";
import LockButton from "../../shared/lock-button";
import ExportedButton from "../../shared/exported-button";
import PackageStatus from "../../shared/package-status";
import ViewButton from "../../shared/view-button/view-button-view";
import {
  loadPackageForms,
  togglePackageExported,
  clearPackageForms,
  togglePackageLock,
  getUsersPackagesSelector,
  getUser,
  getUserSelector,
} from "../../../store/admin";

const Header = ({ ...props }) => {
  const { userId, userPackage } = props;

  const onExportAll = (e, packageId, userId) => {
    e.preventDefault();
    window.open(
      `/export-all/${userId}/${packageId}`,
      "Export All",
      "width=500,height=500"
    );
  };

  const onToggleExport = async (e, packageId, userId) => {
    e.preventDefault();
    const { togglePackageExported, getUser } = props;

    const patientSubmission = {
      packageId,
      userId,
    };

    //reload user to keep open
    togglePackageExported(patientSubmission);
    await getUser(userId);
  };

  const onToggleView = async (e, packageId, userId, hide) => {
    e.preventDefault();

    const { loadPackageForms, getUser, clearPackageForms } = props;

    const userPackage = {
      packageId,
      userId,
    };

    if (hide) {
      clearPackageForms(userPackage);
    } else {
      loadPackageForms(userPackage);
    }
    await getUser(userId);
  };

  const onToggleLock = async (e, packageId, userId) => {
    e.preventDefault();
    const { togglePackageLock, getUser } = props;

    const patientSubmission = {
      packageId,
      userId,
    };

    //reload user to keep open
    togglePackageLock(patientSubmission);
    await getUser(userId);
  };

  return (
    <Fragment>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography style={{ marginTop: "13px" }}>
              <b>{userPackage.package.name}</b>
            </Typography>
          </Grid>
          <Grid item xs>
            <ExportedButton
              packageId={userPackage.package.id}
              userId={userId}
              handleToggleExport={onToggleExport}
              isExported={userPackage.isExported}
            />
          </Grid>
          <Grid item xs>
            <ViewButton
              userId={userId}
              packageId={userPackage.package.id}
              forms={userPackage.forms}
              handelToggleView={onToggleView}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} sm={2}>
        <ExportAllButton
          packageId={userPackage.package.id}
          userId={userId}
          handleExportAll={onExportAll}
        />
      </Grid>
      <Grid item xs={4} sm={2}>
        <LockButton
          packageId={userPackage.package.id}
          userId={userId}
          isLocked={userPackage.isLocked}
          handleToggleLock={onToggleLock}
        />
      </Grid>
      <Grid item xs={4} sm={2}>
        <PackageStatus isComplete={userPackage.isComplete} />
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usersPackages: getUsersPackagesSelector(state),
    user: getUserSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        getUser,
        togglePackageLock,
        togglePackageExported,
        loadPackageForms,
        clearPackageForms,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
