//roles
export const roles = {
  administrator: 1,
  staff: 2,
  patient: 3,
  officeAdministrator: 4,
  manager: 5
};

//locations
export const locations = {
  all: 0,
};

//permissions
export const permissions = {
  manageSettings: 1,
  manageUsers: 2,
  managePackages: 3,
  manageApplications: 4,
  manageForms: 5,
  manageEmailTemplates: 6,
  manageLocations: 7,
  managePatients: 8,
  manageRoles: 9,
  managePermissions: 10
};

//show
export const showResult = {
  completed: "complete",
  notCompleted: "notCompleted",
  exported: "exported",
};

//screeners
export const staffScreening= {
  formUrl: "https://formio.canadacentral.cloudapp.azure.com:3001/staffscreeningv2",
  clearedEmailTemplateId: "d-8d16e6393a83402a9d1eb5fbe6a5c101",
  failedEmailTemplateId: "d-9b99ca507b6c48c79a2a39f08d8b565b",
  hrEmail: "hr@medsleep.com"
};

export const covidRapidTests= {
  formUrl: "https://formio.canadacentral.cloudapp.azure.com:3001/covidrapidtest",
  submissionUrlBase: "https://formio.canadacentral.cloudapp.azure.com:3001/#/form",
  clearedEmailTemplateId: "d-645f80ac8bee4265b516a09606d9ee52",
  failedEmailTemplateId: "d-aa838e94e806418dbcb0638fc52fa439",
  hrEmail: "hr@medsleep.com"
};

export const isAdministrator = (roleId) => {
  return roles.administrator === roleId;
};

export const isAdministratorOrManager = (roleId) => {
  return roles.administrator === roleId || roles.manager === roleId;
};

export const isOfficeAdministrator = (roleId) => {
  return roles.officeAdministrator === roleId;
};

export const isStaff = (roleId) => {
  return roles.staff === roleId;
};

export const isPatient = (roleId) => {
  return roles.patient === roleId;
};

export const canManageSettings = (rolePermissions) => {
  return rolePermissions.find(
    (rp) => rp.permissionId === permissions.manageSettings
  );
};

export const canManageUsers = (rolePermissions) => {
  return rolePermissions.find(
    (rp) => rp.permissionId === permissions.manageUsers
  );
};
export const canManagePatients = (rolePermissions) => {
  return rolePermissions.find(
    (rp) => rp.permissionId === permissions.managePatients
  );
};

export const canManagePackages = (rolePermissions) => {
  return rolePermissions.find(
    (rp) => rp.permissionId === permissions.managePackages
  );
};

export const canManageRoles = (rolePermissions) => {
  return rolePermissions.find(
    (rp) => rp.permissionId === permissions.manageRoles
  );
};

export const canManagePermissions = (rolePermissions) => {
  return rolePermissions.find(
    (rp) => rp.permissionId === permissions.managePermissions
  );
};

export const canManageApplications = (rolePermissions) => {
  return rolePermissions.find(
    (rp) => rp.permissionId === permissions.manageApplications
  );
};

export const canManageForms = (rolePermissions) => {
  return rolePermissions.find(
    (rp) => rp.permissionId === permissions.manageForms
  );
};

export const canManageEmailTemplates = (rolePermissions) => {
  return rolePermissions.find(
    (rp) => rp.permissionId === permissions.manageEmailTemplates
  );
};

export const canManageLocations = (rolePermissions) => {
  return rolePermissions.find(
    (rp) => rp.permissionId === permissions.manageLocations
  );
};

export const getNameFromPermissionId = (id, permissions) => {
  return permissions.find((p) => p.permissionId === id).name;
};

export const getNameFromRoleId = (id, roles) => {
  return roles.find((r) => r.roleId === id).name;
};

export const getNameFromLocationId = (id, locations) => {
  var location = locations.find((l) => l.id === id);
  if (location) return location.name;
};

export const getNameFromFormId = (id, forms) => {
  return forms.find((r) => r.id === id).name;
};

export const getNameFromPackageId = (id, packges) => {
  return packges.find((p) => p.id === id).name;
};

//Common functions
export const handleSelectChange = (e, setFieldValue, name) => {
  e.preventDefault();
  setFieldValue(name, e.target.value);
};

export const keyPressed = (e, func) => {
  if (e.key === "Enter") {
    func();
  }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
