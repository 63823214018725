export const downloadAttachment = (data) => {
  for (let [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      for (let [key, value] of Object.entries(value)) {
        if (value.url) {
          const linkSource = `${value.url}`;
          const downloadLink = document.createElement("a");
          const fileName = `${value.name}`;
          downloadLink.href = linkSource;
          downloadLink.title = fileName;
          downloadLink.download = fileName;
          downloadLink.target = "_blank";
          downloadLink.click();
        }
      }
    }
  }
};
