import React from 'react';
import {Formik, ErrorMessage} from 'formik';
import { Button, Grid, Typography, TextField } from '@material-ui/core';
import { stripeSettingsSchema } from './stripe-settings-schema';

const StripeSettingsView = ({ ...props }) => {

    const { configuration, handleSaveStripeSettings} = props;
    const { stripeSecretKey } = configuration;

    return(
        <Formik
            enableReinitialize={true}
            initialValues={{ secretKey: stripeSecretKey ? stripeSecretKey : ""}}
            validationSchema={stripeSettingsSchema}

            onSubmit={(values) => {
                handleSaveStripeSettings(values);
            }}

            render={({ values, setFieldValue, handleChange, handleSubmit, handleBlur, errors, touched, ...formProps }) => (
                <form onSubmit={handleSubmit}>
                    
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            Stripe Settings
                        </Typography>
                    </Grid>             
                    <Grid item xs={12}>
                        <TextField
                            error={(errors.secretKey && touched.secretKey) ? true : false}
                            name="secretKey"
                            type="text"
                            label="Secret Key"
                            margin="normal"
                            fullWidth
                            type="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.secretKey}
                        />
                        <ErrorMessage name="secretKey">{msg => <div className="error error-message">{msg}</div>}</ErrorMessage>
                    </Grid>
                    <Grid item xs={3} align="right">
                        <Button type="submit" variant="contained" color="primary" fullWidth>Save</Button>
                    </Grid>
                
                </form>
            )}
        />
    );    
}

export default StripeSettingsView