import React, { Fragment } from "react";
import { Tooltip, IconButton } from "@material-ui/core";

import NotInterestedIcon from "@material-ui/icons/NotInterested";
import VisibilityIcon from "@material-ui/icons/Visibility";

const ViewFormButton = ({ ...props }) => {
  const { formId, packageId, userId, handleFormClick, isComplete } = props;

  return (
    <Fragment>
      {isComplete && (
        <Tooltip
          title="View"
          onClick={(e) => handleFormClick(e, formId, packageId, userId)}
        >
          <IconButton aria-label="view">
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      )}
      {!isComplete && (
        <Tooltip title="Not Complete">
          <IconButton aria-label="not complete">
            <NotInterestedIcon />
          </IconButton>
        </Tooltip>
      )}
    </Fragment>
  );
};

export default ViewFormButton;
