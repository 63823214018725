import * as Yup from "yup";

export const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(
      /(?=^.{8,}$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      `
      <div>
        The Password Requirements
        <ul>
            <li>8 or more characters</li> 
            <li>At least 1 upper case letter</li> 
            <li>At least 1 lower case</li> 
            <li>No symbols</li> 
        </ul>
      </div>
      `
    )
    .required("Please enter a new password"),

  confirmNewPassword: Yup.string().oneOf(
    [Yup.ref("newPassword"), null],
    "Passwords must match"
  ),
});
