import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import auth from "../services/authService";
import userService from "../services/userService";

const slice = createSlice({
  name: "user",
  initialState: {
    error: "",
    user: "",
    token: "",
    rolePermissions: [],
    userPackages: [],
    secondaryLocations: []
  },
  reducers: {
    setCurrentUser: (state, action) => {
      const { user, token, rolePermissions, userPackages, secondaryLocations } = action.payload;
      state.user = user;
      state.token = token;
      state.rolePermissions = rolePermissions;
      state.userPackages = userPackages;
      state.secondaryLocations = secondaryLocations;
    },

    setUserError: (state, action) => {
      const { error } = action.payload;
      state.error = error;
    },

    setCompletePackage: (state, action) => {
      const { patientSubmission } = action.payload;
      const { packageId } = patientSubmission;

      const userPackage = state.userPackages.find(
        (up) => up.package.id === packageId
      );
      if (userPackage) {
        userPackage.isComplete = true;
      }
    },

    setLockPackage: (state, action) => {
      const { patientSubmission } = action.payload;
      const { packageId } = patientSubmission;

      const userPackage = state.userPackages.find(
        (up) => up.package.id === packageId
      );
      if (userPackage) {
        userPackage.isLocked = true;
      }
    },

    setCompleteForm: (state, action) => {
      const { patientSubmission } = action.payload;
      const { formId, packageId } = patientSubmission;

      const userPackage = state.userPackages.find(
        (up) => up.package.id === packageId
      );
      if (userPackage) {
        var form = userPackage.forms.find((f) => f.form.id === formId);
        if (form) {
          form.isComplete = true;
        }
      }
    },
  },
});

export const {
  setCurrentUser,
  setUserError,
  setCompletePackage,
  setCompleteForm,
  setLockPackage,
} = slice.actions;
export const reducer = slice.reducer;

//action creators
export const login = (username, password) => async (dispatch) => {
  try {
    await auth.login(username, password);
    const token = auth.getCurrentUser();
    const { data: user } = await userService.getUser(token.primarysid);
    const { data: rolePermissions } = await userService.getRolePermissions(
      user.roleId
    );
    const { data: userPackages } = await userService.getUserPackagesForms(
      user.id
    );
    const { data: secondaryLocations } = await userService.getUserSecondaryLocations(
      user.id
    );

    return dispatch({
      type: setCurrentUser.type,
      payload: { user, token, rolePermissions, userPackages, secondaryLocations },
    });
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      var error = ex.response.data;
      return dispatch({ type: setUserError.type, payload: { error } });
    }
  }
};

export const setUserFromToken = (token) => async (dispatch) => {

  const { data: user } = await userService.getUser(token.primarysid);

  const { data: rolePermissions } = await userService.getRolePermissions(
    user.roleId
  );
  
  const { data: userPackages } = await userService.getUserPackagesForms(
    user.id
  );

  const { data: secondaryLocations } = await userService.getUserSecondaryLocations(
    user.id
  );

  return dispatch({
    type: setCurrentUser.type,
    payload: { user, token, rolePermissions, userPackages, secondaryLocations },
  });
};

export const saveSubmission = (patientSubmission) => async (dispatch) => {
  const { data: result } = await userService.saveSubmission(patientSubmission);

  //update store
  dispatch({
    type: setCompleteForm.type,
    payload: { patientSubmission: result.patientSubmission },
  });

  if (result.isPackageComplete) {
    return dispatch({
      type: setCompletePackage.type,
      payload: { patientSubmission: result.patientSubmission },
    });
  }
};

export const lockPackage = (patientSubmission) => async (dispatch) => {
  await userService.togglePatientPackageLock(patientSubmission);
  //update store
  dispatch({ type: setLockPackage.type, payload: { patientSubmission } });
};

export const resetPassword = (userId,password) => async (dispatch) => {
  await userService.resetPassword(userId,password);
};

//memoized selector
export const getCurrentUser = createSelector(
  (state) => state.currentUser.user,
  (user) => {
    return user;
  }
);

export const getUserError = createSelector(
  (state) => state.currentUser.error,
  (error) => {
    return error;
  }
);

export const getUserPermissions = createSelector(
  (state) => state.currentUser.rolePermissions,
  (rolePermissions) => {
    return rolePermissions;
  }
);

export const getUserPackagesSelector = createSelector(
  (state) => state.currentUser.userPackages,
  (userPackages) => {
    return userPackages;
  }
);

export const getUserSecondaryLocationsSelector = createSelector(
  (state) => state.currentUser.secondaryLocations,
  (secondaryLocations) => {
    return secondaryLocations;
  }
);
