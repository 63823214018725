import * as Yup from 'yup';

export const userSchema = Yup.object().shape({

    username: Yup.string().email("Please enter a valid email").required("Please enter your username"),
    password: Yup.string().required("Please enter your password"),
    firstName: Yup.string().required("Please enter your first name"),
    lastName: Yup.string().required("Please enter your last name"),
    locationId: Yup.number().min(1,"Please select a location"),
    roleId: Yup.number().min(1,"Please select a role"),
    emailTemplateId: Yup.number().test(
        "email-template-check",
        "Please select an email template",
        function(value){

            if(this.parent.checked){
                return value && value > 0;
            }
            else
                return true;
        }
    ),   
    packageIds: Yup.array().test(
        "package-ids-check",
        "Please select a package",
        function(value){
            if(this.parent.checked){
                return value && value.length > 0;
            }
            else
                return true;
        }
    ),    
})
