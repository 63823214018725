import React from 'react'
import { 
    Tooltip,IconButton
    } from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';



const ExportButton = ({...props}) => {

    const { handleExport, formId, packageId, userId} = props;

    return (
        <Tooltip title="Export" onClick={e => handleExport(e, formId, packageId, userId)}>
            <IconButton aria-label="export">
                <GetAppIcon />
            </IconButton>
        </Tooltip>     
    )
}

export default ExportButton;