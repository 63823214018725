import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from "reactstrap";
import { Link as RouterLink } from "react-router-dom";
import { Button, makeStyles } from "@material-ui/core";
import {
  setUserFromToken,
  getCurrentUser,
  getUserPermissions,
} from "../store/user";
import auth from "../services/authService";
import {
  canManageSettings,
  canManageUsers,
  canManageForms,
  canManagePackages,
  canManageEmailTemplates,
  isPatient,
  canManageApplications,
  canManageLocations,
  canManagePatients,
} from "../utils/constants";
import logo from "../Images/medsleep-logo.jpg";

const useStyles = makeStyles((theme) => ({
  navLogo: theme.navLogo,
}));

const NavMenu = ({ user, ...props }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      try {
        const token = auth.getCurrentUser();
        const { setUserFromToken } = props;
        if (token) {
          const {
            payload: { rolePermissions },
          } = await setUserFromToken(token);
        }
      } catch (e) {}
    };
    getUser();
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3"
        light
      >
        <Container>
          <NavbarBrand>
            <img src={logo} className={classes.navLogo} alt="MedSleep Logo" />
          </NavbarBrand>
          <NavbarToggler onClick={(e) => toggle()} className="mr-2" />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            isOpen={isOpen}
            navbar
          >
            <ul className="navbar-nav flex-grow">
              {!user && (
                <Button
                  component={RouterLink}
                  to="/login"
                  color="inherit"
                  className={classes.menuButton}
                >
                  Login
                </Button>
              )}
              {user && props.rolePermissions && isPatient(user.roleId) && (
                <Button
                  component={RouterLink}
                  to="/patient-dashboard"
                  color="inherit"
                  className={classes.menuButton}
                >
                  Dashboard
                </Button>
              )}
              {props.rolePermissions &&
                (canManageUsers(props.rolePermissions) ||
                  canManagePatients(props.rolePermissions)) && (
                  <Button
                    component={RouterLink}
                    to="/user-management"
                    color="inherit"
                    className={classes.menuButton}
                  >
                    Users
                  </Button>
                )}
              {props.rolePermissions &&
                canManageApplications(props.rolePermissions) && (
                  <Button
                    component={RouterLink}
                    to="/application-management"
                    color="inherit"
                    className={classes.menuButton}
                  >
                    Applications
                  </Button>
                )}
              {props.rolePermissions &&
                canManageForms(props.rolePermissions) && (
                  <Button
                    component={RouterLink}
                    to="/form-management"
                    color="inherit"
                    className={classes.menuButton}
                  >
                    Forms
                  </Button>
                )}
              {props.rolePermissions &&
                canManagePackages(props.rolePermissions) && (
                  <Button
                    component={RouterLink}
                    to="/package-management"
                    color="inherit"
                    className={classes.menuButton}
                  >
                    Packages
                  </Button>
                )}
              {props.rolePermissions &&
                canManageEmailTemplates(props.rolePermissions) && (
                  <Button
                    component={RouterLink}
                    to="/email-template-management"
                    color="inherit"
                    className={classes.menuButton}
                  >
                    Email Templates
                  </Button>
                )}
              {props.rolePermissions &&
                canManageLocations(props.rolePermissions) && (
                  <Button
                    component={RouterLink}
                    to="/location-management"
                    color="inherit"
                    className={classes.menuButton}
                  >
                    Locations
                  </Button>
                )}
              {props.rolePermissions &&
                canManageSettings(props.rolePermissions) && (
                  <Button
                    component={RouterLink}
                    to="/settings"
                    color="inherit"
                    className={classes.menuButton}
                  >
                    Settings
                  </Button>
                )}
              {user && (
                <Button
                  component={RouterLink}
                  to="/logout"
                  color="inherit"
                  className={classes.menuButton}
                >
                  Logout
                </Button>
              )}
            </ul>
          </Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    user: getCurrentUser(state),
    rolePermissions: getUserPermissions(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ setUserFromToken }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
