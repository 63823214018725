import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "../../../utils/history";
import {
  Grid,
  makeStyles,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { locationSchema } from "./location-schema";
import { toast } from "react-toastify";
import {
  getLocations,
  getLocation,
  getLocationSelector,
  getLocationsSelector,
  saveLocation,
} from "../../../store/admin";

const useStyles = makeStyles((theme) => ({
  backArrowOutter: theme.backArrowOutter,
  darkArrow: theme.darkArrow,
  form: theme.form,
  select: theme.select,
}));

const Location = ({ ...props }) => {
  const classes = useStyles();
  const {
    match: { params },
    location = {},
  } = props;
  const [disabledSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    const init = async () => {
      const { getLocation, getLocations } = props;
      const {
        match: { params },
      } = props;

      await getLocations();

      if (params.id !== "new") await getLocation(params.id);
    };
    init();
  }, []);

  const handleSave = async (values) => {
    setDisableSubmit(true);
    const {
      match: { params },
      saveLocation,
      locations,
    } = props;

    const location = {
      id: params.id === "new" ? 0 : params.id,
      name: values.name,
      address: values.address,
      telephone: values.telephone,
      fax: values.fax,
      email: values.email,
    };

    const exists = locations.find((l) => l.name === location.name);

    if (exists && exists.id !== parseInt(params.id)) {
      toast.error(`Location already exists with name ${location.name}`);
      setDisableSubmit(false);
      return;
    } else {
      await saveLocation(location);
      history.push("/location-management");
    }
  };

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.backArrowOutter}>
          <span
            onClick={(e) => history.push("/location-management")}
            className={classes.darkArrow}
          >
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Back to
            Location Management{" "}
          </span>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            {params.id === "new"
              ? "New Location"
              : `Edit Location ${params.id} `}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: location.name ? location.name : "",
              address: location.address ? location.address : "",
              telephone: location.telephone ? location.telephone : "",
              fax: location.fax ? location.fax : "",
              email: location.email ? location.email : "",
            }}
            validationSchema={locationSchema}
            onSubmit={(values) => {
              handleSave(values);
            }}
            render={({
              values,
              setFieldValue,
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
              touched,
              ...formProps
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      error={errors.name && touched.name ? true : false}
                      name="name"
                      type="text"
                      label="Name"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <ErrorMessage name="name">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      error={errors.address && touched.address ? true : false}
                      name="address"
                      type="text"
                      label="Address"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                    />
                    <ErrorMessage name="address">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="telephone"
                      type="text"
                      label="Telephone"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.telephone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="fax"
                      type="text"
                      label="Fax"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fax}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="email"
                      error={errors.email && touched.email ? true : false}
                      type="text"
                      label="Email"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <ErrorMessage name="email">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>

                  <Grid item xs={12} align="right">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={disabledSubmit}
                    >
                      Save Location
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    location: getLocationSelector(state),
    locations: getLocationsSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      { getLocation, getLocations, saveLocation },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
