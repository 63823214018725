import React from "react";
import { Grid, Typography } from "@material-ui/core";

const Loading = () => (
  <Grid item xs={12}>
    <Typography variant="h6">Loading...</Typography>
  </Grid>
);

export default Loading;
