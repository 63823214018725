import React, { Fragment } from 'react'
import { 
    Tooltip,IconButton
    } from '@material-ui/core';

import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const LockButton = ({...props}) => {

    const { handleToggleLock, isLocked, packageId, userId} = props;

    return (
        <Fragment>
            {isLocked && 
                <Tooltip title="Unlock" onClick={e => handleToggleLock(e, packageId, userId)}>
                    <IconButton aria-label="unlock">
                        <LockOpenIcon />
                    </IconButton>
                </Tooltip>   
            }
            {!isLocked &&
                <Tooltip title="Lock" onClick={e => handleToggleLock(e, packageId, userId)}>
                    <IconButton aria-label="lock">
                        <LockIcon />
                    </IconButton>
                </Tooltip>   
            }
        </Fragment>
    )
}

export default LockButton;