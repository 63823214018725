import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "../../../utils/history";
import {
  handleSelectChange,
  getNameFromFormId,
  MenuProps,
} from "../../../utils/constants";
import {
  Grid,
  makeStyles,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Chip,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { packageSchema } from "./package-schema";
import { toast } from "react-toastify";
import {
  getPackages,
  getPackage,
  getPackageSelector,
  getPackagesSelector,
  getForms,
  getFormsSelector,
  savePackage,
  getPackageFormsSelector,
} from "../../../store/admin";

const useStyles = makeStyles((theme) => ({
  backArrowOutter: theme.backArrowOutter,
  darkArrow: theme.darkArrow,
  form: theme.form,
  chips: theme.chips,
  chip: theme.chip,
  multiSelectEmpty: theme.multiSelectEmpty,
  multiSelect: theme.multiSelect,
  checked: theme.checked,
}));

const onCheck = (e, setFieldValue, name) => {
  setFieldValue(name, e.target.checked);
};

const Package = ({ ...props }) => {
  const classes = useStyles();
  const {
    match: { params },
    _package = {},
  } = props;
  const [disabledSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    const init = async () => {
      const { getPackage, getPackages, getForms } = props;
      const {
        match: { params },
      } = props;

      await getForms();
      await getPackages();

      if (params.id !== "new") await getPackage(params.id);
    };
    init();
  }, []);

  const handleSave = async (values) => {
    setDisableSubmit(true);
    const {
      match: { params },
      savePackage,
      packages,
    } = props;

    const _package = {
      id: params.id === "new" ? 0 : params.id,
      name: values.name,
      isConsentPackage: values.isConsentPackage,
    };

    const exists = packages.find((p) => p.name === _package.name);

    if (exists && exists.id !== parseInt(params.id)) {
      toast.error(`Package already exists with name ${_package.name}`);
      setDisableSubmit(false);
      return;
    } else {
      var packageForm = {
        package: _package,
        formIds: values.formIds,
      };

      await savePackage(packageForm);
      history.push("/package-management");
    }
  };

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.backArrowOutter}>
          <span
            onClick={(e) => history.push("/package-management")}
            className={classes.darkArrow}
          >
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Back to
            Package Management{" "}
          </span>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            {params.id === "new" ? "New Package" : `Edit Package ${params.id} `}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: _package.name ? _package.name : "",
              formIds: props.packageForms ? props.packageForms : [],
              isConsentPackage: _package.isConsentPackage
                ? _package.isConsentPackage
                : false,
            }}
            validationSchema={packageSchema}
            onSubmit={(values) => {
              handleSave(values);
            }}
            render={({
              values,
              setFieldValue,
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
              touched,
              ...formProps
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={errors.name && touched.name ? true : false}
                      name="name"
                      type="text"
                      label="Name"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <ErrorMessage name="name">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2">
                      A consent package is required to be filled out by a
                      patient prior to completing assigned packages.
                    </Typography>
                    <FormControlLabel
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          checked={values.isConsentPackage}
                          onChange={(e) =>
                            onCheck(e, setFieldValue, "isConsentPackage")
                          }
                          name="sendEmail"
                          color="primary"
                        />
                      }
                      label="Is this package a consent package?"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      className={
                        values.formIds.length > 0
                          ? classes.multiSelect
                          : classes.multiSelectEmpty
                      }
                    >
                      <InputLabel id="formIds">Forms</InputLabel>
                      <Select
                        id="formIds"
                        error={errors.formIds && touched.formIds ? true : false}
                        multiple
                        value={values.formIds}
                        onChange={(e) =>
                          handleSelectChange(e, setFieldValue, "formIds")
                        }
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value, index) => (
                              <Chip
                                key={`${value}-${index}`}
                                label={getNameFromFormId(value, props.forms)}
                                className={classes.chip}
                              />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {props.forms &&
                          props.forms.map((form) => (
                            <MenuItem key={form.id} value={form.id}>
                              {form.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <ErrorMessage name="formIds">
                      {(msg) => (
                        <div className="error error-message">{msg}</div>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} align="right">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={disabledSubmit}
                    >
                      Save Package
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    _package: getPackageSelector(state),
    packages: getPackagesSelector(state),
    forms: getFormsSelector(state),
    packageForms: getPackageFormsSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      { getPackage, getPackages, savePackage, getForms },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Package);
