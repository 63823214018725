import http from "./httpService";

const apiEndPoint = `${window.location.origin}/api/email`;

export function send(data) {
  return http.post(apiEndPoint + "/send", data);
}

export default {
  send,
};
