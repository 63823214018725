import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { 
    Grid, 
    Typography, 
    makeStyles
} from '@material-ui/core';
import FormIOSettings from './form-io-settings/form-io-settings-view';
import SendGridSettings from './send-grid-settings/send-grid-settings-view';
import StripeSettings from './stripe-settings/stripe-settings-view';
import { getConfiguration, getSettings, saveSettings } from '../../store/setting';
import { deleteExportedPatients } from '../../store/admin';
import auth from "../../services/authService";
import { setUserFromToken } from "../../store/user";
import { canManageSettings } from '../../utils/constants';
import { history } from "../../utils/history";
import AdminFunctionsSettings from './admin-functions-settings';

const useStyles = makeStyles(theme => ({
    button: theme.button
}));


const Settings = ({ ...props }) => {

    const classes = useStyles();

    useEffect(() => {
        const init = async () => {

            const {
                getSettings,
                setUserFromToken,
              } = props;
            await getSettings();

            const token = auth.getCurrentUser();

            if(token){
                const {
                    payload: { rolePermissions },
                  } = await setUserFromToken(token);
    
                  if(!canManageSettings(rolePermissions)){
                      history.push("/login");
                  }
            }
        }
        init();
        
    }, []);

    const onSaveFormIOSettings = async (values) => {

        const { saveSettings, configuration } = props;
        var setting = { ...configuration };
        setting.formioLiveEndpoint = values.liveEndpoint;
        setting.formioUsername = values.username;
        setting.formioPassword = values.password;

        await saveSettings(setting);
    }

    const onSaveSendGridSettings = async (values) => {

        const { saveSettings, configuration } = props;
        var setting = { ...configuration };
        setting.sendGridAPIKey = values.apiKey;
        setting.sendGridFromEmail = values.fromEmail;
        setting.sendGridAPIEndpoint = values.apiEndpoint;

        await saveSettings(setting);    
    }

    const onSaveStripeSettings = async (values) => {

        const { saveSettings, configuration } = props;
        var setting = { ...configuration };
        setting.stripeSecretKey = values.secretKey;

        await saveSettings(setting);   
    }    

    const onConfirmDeleteExportedPatientData = async (e, setOpen) => {

        e.preventDefault();
        const { deleteExportedPatients } = props;
        setOpen(false);
        await deleteExportedPatients();
    };

    return(
        <Fragment>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom>
                        Settings
                    </Typography>
                </Grid>  
                <AdminFunctionsSettings 
                    handleDeleteExportedPatientData={onConfirmDeleteExportedPatientData}
                    classes={classes}
                />
                <Grid item xs={12} sm={6}>
                    <FormIOSettings 
                        configuration={props.configuration}
                        handleSaveFormIOSettings={onSaveFormIOSettings} 
                        classes={classes} 
                        />
                </Grid>    
                <Grid item xs={12} sm={6}>
                    <SendGridSettings 
                        configuration={props.configuration}
                        handleSaveSendGridSettings={onSaveSendGridSettings} 
                        classes={classes} 
                        />
                </Grid>   
                <Grid item xs={12} sm={6}>
                    <StripeSettings 
                        configuration={props.configuration}
                        handleSaveStripeSettings={onSaveStripeSettings} 
                        classes={classes} 
                        />
                </Grid>   
            </Grid>          
         
        </Fragment>
        
    );    
}

const mapStateToProps = state => {

    return {
        configuration: getConfiguration(state)
    };

};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({getSettings,saveSettings, setUserFromToken, deleteExportedPatients}, dispatch)   
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings)