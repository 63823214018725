import http from "./httpService";
import auth from "./authService";

const apiEndPoint = `${window.location.origin}/api/admin`;

http.setJwt(auth.getJwt());

export function getSettings() {
  return http.get(`${apiEndPoint}/settings`);
}

export function saveSettings(setting) {
  const body = { ...setting };
  return http.put(apiEndPoint + "/settings/edit", body);
}

export function getRoles() {
  return http.get(`${apiEndPoint}/roles`);
}

export function getRole(id) {
  return http.get(`${apiEndPoint}/role/${id}`);
}

export function getRolePermissions(id) {
  return http.get(`${apiEndPoint}/role/${id}/permissions`);
}

export function saveRole(rolePermission) {
  if (rolePermission.role.id) {
    const body = { ...rolePermission };
    return http.put(apiEndPoint + "/role/edit", body);
  }
  return http.post(apiEndPoint + "/role/new", rolePermission);
}

export function deleteRole(id) {
  return http.delete(`${apiEndPoint}/role/${id}`);
}

export function getPermissions() {
  return http.get(`${apiEndPoint}/permissions`);
}

export function getPermission(id) {
  return http.get(`${apiEndPoint}/permission/${id}`);
}

export function savePermission(permission) {
  if (permission.id) {
    const body = { ...permission };
    return http.put(apiEndPoint + "/permission/edit", body);
  }
  return http.post(apiEndPoint + "/permission/new", permission);
}

export function deletePermission(id) {
  return http.delete(`${apiEndPoint}/permission/${id}`);
}

export function getForms() {
  return http.get(`${apiEndPoint}/forms`);
}

export function getForm(id) {
  return http.get(`${apiEndPoint}/form/${id}`);
}

export function saveForm(form) {
  if (form.id) {
    const body = { ...form };
    return http.put(apiEndPoint + "/form/edit", body);
  }
  return http.post(apiEndPoint + "/form/new", form);
}

export function deleteForm(id) {
  return http.delete(`${apiEndPoint}/form/${id}`);
}

export function getPackages() {
  return http.get(`${apiEndPoint}/packages`);
}

export function getPackage(id) {
  return http.get(`${apiEndPoint}/package/${id}`);
}

export function savePackage(packageForm) {
  if (packageForm.package.id) {
    const body = { ...packageForm };
    return http.put(apiEndPoint + "/package/edit", body);
  }
  return http.post(apiEndPoint + "/package/new", packageForm);
}

export function deletePackage(id) {
  return http.delete(`${apiEndPoint}/package/${id}`);
}

export function getPackageForms(id) {
  return http.get(`${apiEndPoint}/package/${id}/forms`);
}

export function getEmailTemplates() {
  return http.get(`${apiEndPoint}/emailtemplates`);
}

export function getEmailTemplate(id) {
  return http.get(`${apiEndPoint}/emailtemplate/${id}`);
}

export function saveEmailTemplate(emailTemplate) {
  if (emailTemplate.id) {
    const body = { ...emailTemplate };
    return http.put(apiEndPoint + "/emailtemplate/edit", body);
  }
  return http.post(apiEndPoint + "/emailtemplate/new", emailTemplate);
}

export function deleteEmailTemplate(id) {
  return http.delete(`${apiEndPoint}/emailtemplate/${id}`);
}

export function getLocations() {
  return http.get(`${apiEndPoint}/locations`);
}

export function getLocation(id) {
  return http.get(`${apiEndPoint}/location/${id}`);
}

export function saveLocation(location) {
  if (location.id) {
    const body = { ...location };
    return http.put(apiEndPoint + "/location/edit", body);
  }
  return http.post(apiEndPoint + "/location/new", location);
}

export function deleteLocation(id) {
  return http.delete(`${apiEndPoint}/location/${id}`);
}

export function saveEmailTemplateAttachments(saveEmailTemplateAttachment) {
  return http.post(
    apiEndPoint + "/emailtemplate/attachments",
    saveEmailTemplateAttachment
  );
}

export function getEmailTemplateAttachments(id) {
  return http.get(`${apiEndPoint}/emailtemplate/attachments/${id}`);
}

export function deleteEmailTemplateAttachment(id) {
  return http.delete(`${apiEndPoint}/emailtemplate/attachments/${id}`);
}

export function deleteExportedPatients() {
  return http.get(`${apiEndPoint}/delete/exported/patients`);
}



export default {
  getSettings,
  saveSettings,
  getRoles,
  getRole,
  saveRole,
  deleteRole,
  getPermissions,
  getPermission,
  savePermission,
  deletePermission,
  getRolePermissions,
  getForms,
  getForm,
  saveForm,
  deleteForm,
  getPackages,
  getPackage,
  savePackage,
  deletePackage,
  getPackageForms,
  getEmailTemplates,
  getEmailTemplate,
  saveEmailTemplate,
  deleteEmailTemplate,
  getLocations,
  getLocation,
  saveLocation,
  deleteLocation,
  saveEmailTemplateAttachments,
  getEmailTemplateAttachments,
  deleteEmailTemplateAttachment,
  deleteExportedPatients
};
